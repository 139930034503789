<template>
  <div class="body_bg">
    <div class="login-logo">
      <img src="../assets/img/logo.png" width="140"/>
    </div>
    <div class="login_container">
      <div class="login-left-card">
        <img class="girl" src="../assets/img/login-box-girl.png"/>
      </div>
      <div class="login-form">
        <h2>DigitalTwin数字分身平台</h2>
        <el-form ref="loginFormRef" :model="loginForm" :rules="loginRules" label-width="0px">
          <el-form-item  prop="username" label="">
            <el-input v-model="loginForm.username" placeholder="输入手机号" prefix-icon="el-icon-mobile" maxlength="11" clearable></el-input>
          </el-form-item>
          <el-row>
            <el-col :span="14">
              <el-form-item  prop="password" label="">
                <el-input style="border-top:0;" v-model="loginForm.password" maxlength="6" placeholder="请输入验证码" prefix-icon="el-icon-lock" ref="VerifyInput" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6" :offset="4">
              <br>
              <el-button @click="checkVerify" :type="smsBtnType" plain size="small" :disabled="smsDisable">{{smsText}}</el-button>
            </el-col>
          </el-row>
         
          <el-form-item  class="justify-center">
            <el-button type="primary" @click="login" class="btn-submit">登录</el-button>
          </el-form-item>
          <el-form-item class="justify-center">
            <el-checkbox></el-checkbox> 我已阅读并同意 <el-link href="#" target="_blank">《服务协议》</el-link>   <el-link href="#" target="_blank">《隐私政策》</el-link>
          </el-form-item>
        </el-form>
        <div class="justify-center"><br>没有账号? <el-link type="primary">立即注册</el-link></div>
      </div>
      
    </div>
    <!--弹窗-->
    <el-dialog
      title="图形验证"
      :visible.sync="dialogVerify"
      width="370px">
      <span>
        <slide-verify :l="42"
                :r="10"
                :w="330"
                :h="155"
                :imgs=imgsVerify
                slider-text="向右滑动"
                ref="slideblock"
                @success="verifySuccess"
                @fail="verifyFail"
                @refresh="verifyRefresh"
                ></slide-verify>
        <div style="text-align: center;padding-top:16px;" v-html ="verifyMsg"></div>
      </span>

    </el-dialog>
  </div>
</template>

<script>
export default {
  data(){
    return{
      loginForm:{
        username:'',
        password:'',
      },
      //短信验证
      smsText: '获取验证码',
      smsTime: 10,
      smsTimer: null,
      smsDisable: false,
      smsBtnType:'primary',
      dialogVerify:false,
      imgsVerify:[require('../assets/img/imgsVerify-1.jpg'),require('../assets/img/imgsVerify-2.jpg'),require('../assets/img/imgsVerify-3.jpg'),require('../assets/img/imgsVerify-4.jpg'),require('../assets/img/imgsVerify-5.jpg'),require('../assets/img/imgsVerify-6.jpg')],
      //滑动校验
      verifyMsg: '',
      //表单校验
      loginRules:{
          username: [
                { required: true, message: '请输入手机号', trigger: 'blur' },
                { pattern:/^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/, message: "手机号格式错误", trigger: "blur" },
                { min: 11, max: 11, message: '请输入11位手机号', trigger: 'blur' }
              ],
            password: [
            { required: true, message: '请输入验证码', trigger: 'blur' },
            { min: 6, max: 6, message: '请输出6位验证码', trigger: 'blur' }
          ]
      }
    }
  },
  created() {
    const smsTime = localStorage.getItem('smsTime')
    if (smsTime && smsTime > 0) {
      this.smsText = '重新获取 '+smsTime + 'S';
      this.smsBtnType='';
      this.smsTime = smsTime
      this.smsSend()
    }
  },
  methods:{
    //登录表单
    login(){
      //表单校验
      this.$refs.loginFormRef.validate(async valid => {
       if(valid){
        //手机验证接口
        const {data:res} = await this.$http.post('sms/login',{
          phone:this.loginForm.username,
          code:this.loginForm.password
        })
        if(res.status=='200'){
          console.log("登录成功")
          window.sessionStorage.setItem('token',res.sid)
          this.$router.push("/home")
        }else{
          this.$message({
          message: '手机号或验证码错误',
          type: 'error'
          });
        }
       }
       
       
      })
    },
    //打开滑块验证
    checkVerify(){
      //验证手机
      this.$refs.loginFormRef.validateField('username',usernameError => {
       if(!usernameError){
        this.dialogVerify = true
        this.verifyMsg = ''
        //this.$refs.slideblock.reset()
       }
      })
      
    },
    //滑动验证
    async verifySuccess(){
      //发送短信接口
      const {data:res} = await this.$http.post('sms',{
        phone:this.loginForm.username,
      })
      switch(res.status){
      case 402:
      this.$message({
          message: '手机号未注册',
          type: 'error'
        });
        this.dialogVerify = false
      break
      case 200:
      this.$message({
          message: '验证码发送成功，5分钟内有效',
          type: 'success'
        });
        this.verifyMsg = '<font style="color:#67C23A">验证成功</font>'
        setTimeout(() =>{
          this.smsSend()
          this.dialogVerify = false
        }, 500); 
      break
      default:
      }
      //Toast提示
        this.$refs.VerifyInput.focus();
    },
    verifyFail(){
      this.verifyMsg = '<font style="color:#F56C6C">验证失败</font>'
    },
    verifyRefresh(){
      this.verifyMsg = ''
    },
    
    //短信验证
    smsSend(){
      this.smsDisable = true
      this.smsText ='重新获取 '+ this.smsTime + 'S'
      this.smsBtnType='';
      this.smsTimer = setInterval(() => {
        if (this.smsTime > 0) {
          this.smsTime--
          this.smsText = '重新获取 '+this.smsTime + 'S'
          localStorage.setItem('smsTime', this.smsTime) // 注意这行
        } else {
          clearInterval(this.smsTimer)
          this.smsTime = 10
          this.smsDisable = false
          this.smsText = '重新获取'
          this.smsBtnType='primary';
        }
      }, 1000)
    }
    
  }
}
</script>
<style lang="less" scoped>
.body_bg{background-image: url(../assets/img/login-bg.jpg);background-size:100% 100%;width:100%;height: 100%;}
.login-logo{position: fixed;top: 60px;left: 40px;}
 .login_container{width:950px;height: 550px;border:1px solid #dddddd;border-radius: 20px;position: fixed;top: 50%;left: 50%;margin: -275px 0 0 -475px;background: #ffffff;box-shadow:0 0 40px rgba(0,0,0,0.2);}
.login-left-card{width:458px;float: left;height: 550px;border-radius:20px 30px 30px 20px;background-image: url(../assets/img/login-box-bg.webp);background-size:100%;position: relative;}
.login-left-card .girl{position: absolute;bottom:0;left:0;}
.login-form{padding:0 60px;float: right;width: 360px;}
.login-form .btn-submit{height: 48px;width:100%;font-size:16px;margin-top: 16px;}
.el-input{margin-top:8px;}
.el-input /deep/ .el-input__inner {border-top:0;border-radius: 0;border-left: 0;border-right:0;font-size:16px;}
.el-form-item /deep/ .el-form-item__error{text-indent: 30px;line-height: 24px;}
.login-form h2{text-align: center;font-size:24px;color: #333333;padding:100px 0 40px 0 ;}
</style>
