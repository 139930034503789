<template>
<div class="creat-page">
    <!--创建视频导航-->
    <div class="header-creat">
        <el-row>
            <el-col :span="12">
                <h2 v-show="!titleEditState" >
                    {{title}}
                    <i class="el-icon-edit" @click="openTitleEdit"></i>
                </h2>
                <el-input v-model="title" ref="titleInput" placeholder="请作品标题"  v-show="titleEditState" @blur="titleEdit" maxlength="30" style="margin-top: 10px;width:200px;"></el-input>
            </el-col>
            <el-col :span="12" style="text-align: right;height: 100%;">
                <div class="creat-toolbar">
                    <!--
                    <el-dropdown trigger="click" style="height:44px">
                        <el-button type="primary" class="btn-custom-black middle" size="small" @command="changeItem" style="margin-top:14px;">
                            {{videoType==='0'?'16:9':'9:16'}}<i class="el-icon-arrow-down el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-for="(item,index) in videoTypeOption" :key="index" :command="item.value">{{item.label}}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <span style="display:inline-block;padding:20px 20px 0 20px">|</span>
                    -->
                    <el-button plain size="small" v-show="this.save == 0 ? true:false" @click="saveVideo" class="btn-custom-black middle" icon="iconfont icon-custom-save">保存</el-button>
                    <el-button plain size="small" v-show="this.save == 3 ? true:false" class="btn-custom-black middle" icon="el-icon el-icon-loading">保存中</el-button>
                    <el-button plain size="small" v-show="this.save == 4 ? true:false" type="success" class="btn-custom-success middle" icon="el-icon el-icon-success">保存成功</el-button>
                    <el-button type="primary" size="small" class="middle" @click="makeProject">合成视频</el-button>
                </div>
            </el-col>
        </el-row>
    </div>
    <div class="creat-warp">
       
        <!--页面预览区-->
        <div class="creat-left">
          <div class="creat-left-warp">
            <div class="page-list">
                <!--片头-->
                <div :class="['page-video-add',pageViewList.videoHeadFileUrl!=''?'page-video-import':'']" @click="openVideoLibrary('piantou')">
                    <div class="btn-add"><i class="el-icon-film"></i> 添加片头</div>
                    <div class="play" @click.stop="videoPlay(pageViewList.videoHeadFileUrl)"><i class="el-icon-video-play"></i></div>
                    <img class="cover" v-show="pageViewList.videoHeadFileImg!=''" :src="pageViewList.videoHeadFileImg"/>
                    <div class="tool-bar"><i class="el-icon-film" title="更换" @click.stop="openVideoLibrary('piantou')"></i><i class="el-icon-delete" title="删除" @click.stop="pageVideoDel('piantou')"></i></div>
                </div>
                <!--列表页-->
                <div class="pageViewList">
                    <div class="page-view" v-for="(item,i) in pageViewList.pages"  @click="pageViewSelect(i)">
                        <div :class="['page-view-warp',pageViewCur==i?'cur':'']">
                            <i v-show="makeWarning==1 && item.text == ''" class="el-icon-warning tips-warning" title="内容不完整"></i>
                            <div class="tool-bar">
                                <i class="el-icon-top" title="上移" v-show="i>0" @click.stop="pageViewUp(i)"></i>
                                <i class="el-icon-bottom" title="下移" v-show="i<pageViewList.pages.length-1" @click.stop="pageViewDown(i)"></i>
                                <i class="el-icon-copy-document" title="复制" @click.stop="pageViewCopy(i)"></i>
                                <i class="el-icon-delete" title="删除" v-show="pageViewList.pages.length>1"  @click.stop="pageViewDel(i)"></i>
                            </div>
                            <div class="cover">
                                <img class="bg" :src="item.backgroundFileUrl"/>
                                <img class="logo" v-show="item.logoParams[0].fileUrl == ''? false:true" :src="item.logoParams[0].fileUrl" :style="{top:item.logoParams[0].positionY+'px',left:item.logoParams[0].positionX+'px',width:item.logoParams[0].width*item.logoParams[0].scale+'px',height:item.logoParams[0].height*item.logoParams[0].scale+'px'}"/>
                                <img class="human" :src="item.humanParams[0].fileUrl" :style="{top:item.humanParams[0].positionY+'px',left:item.humanParams[0].positionX+'px',width:item.humanParams[0].width*item.humanParams[0].scale+'px',height:item.humanParams[0].height*item.humanParams[0].scale+'px'}"/>
                            </div>
                        </div>
                        <div class="btn-page-add"><el-button size="mini" round plain @click="pageViewAdd(i)"><i class="el-icon el-icon-plus"></i></el-button></div>
                    </div>
                    <el-button type="primary"  icon="el-icon-plus" class="btn-page-add" plain  @click="pageViewAdd(-1)" style="margin-bottom:16px;">添加段落</el-button>
                </div>
                <!--片尾-->
                <div :class="['page-video-add',pageViewList.videoTailFileUrl!=''?'page-video-import':'']" @click="openVideoLibrary('pianwei')">
                    <div class="btn-add"><i class="el-icon-film"></i> 添加片尾</div>
                    <div class="play" @click.stop="videoPlay(pageViewList.videoTailFileUrl)"><i class="el-icon-video-play"></i></div>
                    <img class="cover" v-show="pageViewList.videoTailFileImg!=''" :src="pageViewList.videoTailFileImg"/>
                    <div class="tool-bar"><i class="el-icon-film" title="更换" @click.stop="openVideoLibrary('pianwei')"></i><i class="el-icon-delete" title="删除" @click.stop="pageVideoDel('pianwei')"></i></div>
                </div>
                
            </div>
            
          </div>
        </div>
        <!--视频编辑区--> 
        <div class="creat-center">
            <div class="creat-center-warp">
                <!--视频预览区-->
                <div class="video-edit-warp">
                    <h3 style="display: none;">视频编辑</h3>
                    <div class="video-edit-box" ref="videoEditBox">
                        <div class="quick-tool">
                            <span class="icon-logo" @click="openImgLibrary('logo')" title="设置logo"></span>
                            <div class="bg-grounp">
                                <span class="icon-bg" @click="openImgLibrary('bg')" title="设置背景"></span>
                                <span class="icon-bg-green" @click="setPageColor('green')"></span>
                                <span class="icon-bg-white" @click="setPageColor('white')"></span>
                            </div>
                        </div>
                        <!--16:9编辑框-->
                        <div class="video-16-9" ></div>
                        <!--(val) => {resize(val,'human')}-->
                        <div class="video-edit-con" id="edit-cover" ref="edit-cover" :style="{width:videoZoomW,height:videoZoomH,top:videoZoomMarginT,left:videoZoomMarginL,zoom:videoZoom,'background-image' :'url(' + videoBackgroundUrl + ')'}">
                            <VueDragResize :x="videoLogoX" :y="videoLogoY" :w="videoLogoWidth" :key="dragResizeKey+'logo'" :h="videoLogoHeight" v-on:resizing="(val) => {resize(val,'logo')}" v-on:dragging="(val) => {resize(val,'logo')}" :aspectRatio="true" :isActive="false" :parentScaleX="videoZoom" :parentScaleY="videoZoom">
                                <img :src="videoLogoUrl" style="width: 100%;height: 100%;"/>
                            </VueDragResize>
                            <VueDragResize :x="videoHumanX" :y="videoHumanY" :w="videoHumanWidth" :h="videoHumanHeight" :key="dragResizeKey+'human'" v-on:resizing="(val) => {resize(val,'human')}" v-on:dragging="(val) => {resize(val,'human')}" :aspectRatio="true" :isActive="false" :parentScaleX="videoZoom" :parentScaleY="videoZoom">
                                <img :src="videoHumanUrl" style="width:100%;height:100%"/>
                            </VueDragResize>
                        </div>
                    </div>
                </div>
                <!--文字编辑区-->
                <div class="text-edit-warp">
                    <div class="top-bar">
                        <h3>讲解内容</h3>
                        <div style="height: 0;width:0;overflow: hidden;"><audio :src="auditionUrl" controls ref="auditionPlayer"></audio></div>
                        <el-tooltip class="item" effect="dark" content="该音色仅做试听" placement="left">
                            <span class="btn-audio-play" @click="textAudition"><img src="../assets/img/icon-voice.svg"/></span>
                        </el-tooltip>
                    </div>
                    <el-input
                        type="textarea"
                        placeholder="请输入内容"
                        v-model="textarea"
                        maxlength="1000"
                        show-word-limit
                        rows="10"
                        class="textarea"
                        v-model.lazy="videoText"
                        @keyup.native="saveVideoText"
                        >
                    </el-input>
                    <div class="bottom-bar">
                        <el-row>
                            <el-col :span="18">
                                <!--
                                插入停顿：
                                <el-button type="primary" plain size="mini">0.5秒</el-button>
                                <el-button type="primary" plain size="mini">1秒</el-button>
                                <el-button type="primary" plain size="mini">2秒</el-button>
                                
                                语速：
                                -->
                                &nbsp;
                                <el-select v-model="pageViewList.pages[pageViewCur].timbreParams[0].timbreSpeed" size="mini" placeholder="1倍" @change="timbreSpeedChange" style="width:80px;display: none">
                                    <el-option label="0.5倍" :value="0.5"></el-option>
                                    <el-option label="0.6倍" :value="0.6"></el-option>
                                    <el-option label="0.7倍" :value="0.7"></el-option>
                                    <el-option label="0.8倍" :value="0.8"></el-option>
                                    <el-option label="0.9倍" :value="0.9"></el-option>
                                    <el-option label="1倍" :value="1"></el-option>
                                    <el-option label="1.1倍" :value="1.1"></el-option>
                                    <el-option label="1.2倍" :value="1.2"></el-option>
                                    <el-option label="1.3倍" :value="1.3"></el-option>
                                    <el-option label="1.4倍" :value="1.4"></el-option>
                                    <el-option label="1.5倍" :value="1.5"></el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="6" style="text-align: right;">
                                配音选择：
                                <el-button-group>
                                    <el-button type="primary" icon="" size="small" plain @click="timbrePlay(timbreCur)"><i class="el-icon-video-play"></i> {{this.timbresList[timbreCur].TimbreName}}</el-button>
                                    <el-button type="primary" icon="el-icon-arrow-right" size="small" @click="drawerAudio = true" plain style="border-left-color:#b3d8ff !important;padding:9px"></el-button>
                                </el-button-group>
                            </el-col>
                        </el-row>
                        <div style="height: 0;width:0;overflow: hidden;"><audio :src="timbreUrl" controls ref="timbrePlayer"></audio></div>
                    </div>
                </div>
            </div>
        </div>
        <!--工具条--> 
        <div class="creat-right">
            <div class="video-right-bar">
                <ul class="right-nav">
                    <li @click="drawerHuman = true" :class="{cur:1===rightNavCurIndex}"><i class="iconfont icon-custom-human"></i><br>形象</li>
                    <li @click="drawerVideo = true" :class="{cur:2===rightNavCurIndex}"><i class="iconfont icon-custom-video"></i><br>视频</li>
                    <li @click="drawerAudio = true" :class="{cur:3===rightNavCurIndex}" ><i class="iconfont icon-custom-audio"></i><br>音频</li>
                    <li @click="openImgLibrary" :class="{cur:4===rightNavCurIndex}" ><i class="el-icon-picture-outline-round"></i><br>素材</li>
                </ul>
                <!--选择形象-->
                <el-drawer
                    title=""
                    :modal="false"
                    size="320px"
                    class="drawer"
                    @open="openRightNav(1)"
                    @close="closeRightNav"
                    :visible.sync="drawerHuman"
                    :with-header="false">
                    <h3>选择形象</h3>
                    <div class="drawer-warp">    
                        <ul class="human-list">
                            <li v-for="(item,i) in humanList" @click="humanSelect(i)" :class="['',humanCur==i?'cur':'']" ><img :src="item.ThumbnailUrl"><i>{{item.AnchorName}}</i></li>
                        </ul>
                    </div>
                </el-drawer>
                <!--视频设置-->
                <el-drawer
                    title=""
                    :modal="false"
                    size="320px"
                    class="drawer"
                    @open="openRightNav(2)"
                    @close="closeRightNav"
                    :visible.sync="drawerVideo"
                    :with-header="false">
                    <h3>视频设置</h3>
                    <div class="drawer-warp">
                        
                        <el-form ref="form" :model="formVideoSet" label-width="80px" size="small">
                            <el-form-item label-width="0">
                                <h4>数字人设置</h4>
                            </el-form-item>
                            <el-form-item label="位置：" class="white-label">
                                <el-col :span="9">
                                    <el-input class="input-black" v-model="videoHumanX"></el-input>
                                </el-col>
                                <el-col :span="1">&nbsp;</el-col>
                                <el-col :span="9">
                                    <el-input class="input-black" v-model="videoHumanY"></el-input>
                                </el-col>
                            </el-form-item>
                            <el-form-item label="缩放：" class="white-label">
                                <el-col :span="19"><el-input class="input-black" v-model="pageViewList.pages[pageViewCur].humanParams[0].scale"  @keyup.native="pageViewSelect(pageViewCur)"></el-input></el-col>
                            </el-form-item>
                            <el-form-item label-width="0">
                                <h4>LOGO设置</h4>
                            </el-form-item>
                            <!--无logo-->
                            <el-form-item label="" class="white-label" v-show="videoLogoUrl == ''">
                                <el-col :span="24">
                                    <el-button type="primary" icon="el-icon-plus" class="btn-img-add" @click="openImgLibrary('logo')"></el-button>
                                </el-col>
                            </el-form-item>
                            <!--有logo-->
                            <div v-show="videoLogoUrl != ''">
                                <div class="logo-thumb">
                                    <span><img :src="videoLogoUrl"/></span>
                                    <br>
                                    <el-button size="mini" type="primary" plain @click="openImgLibrary('logo')">更换</el-button>
                                    <el-button size="mini" type="danger" icon="el-icon-delete" plain  @click="delImg('logo')"></el-button>
                                </div>
                                <el-form-item label="位置：" class="white-label">
                                    <el-col :span="9">
                                        <el-input class="input-black" v-model="videoLogoX"></el-input>
                                    </el-col>
                                    <el-col :span="1">&nbsp;</el-col>
                                    <el-col :span="9">
                                        <el-input class="input-black" v-model="videoLogoY"></el-input>
                                    </el-col>
                                </el-form-item>
                                <el-form-item label="缩放：" class="white-label">
                                    <el-col :span="19"><el-input class="input-black" v-model="pageViewList.pages[pageViewCur].logoParams[0].scale"  @keyup.native="pageViewSelect(pageViewCur)"></el-input></el-col>
                                </el-form-item>
                            </div>
                            <el-form-item label-width="0">
                                <h4>背景设置</h4>
                            </el-form-item>
                            <el-form-item label="" class="white-label" v-show="videoBackgroundUrl == ''">
                                <el-button type="primary" icon="el-icon-plus" class="btn-img-add" @click="openImgLibrary('bg')"></el-button>
                            </el-form-item>
                            <div v-show="videoBackgroundUrl != ''">
                                <div class="logo-thumb">
                                    <span><img :src="videoBackgroundUrl"/></span>
                                    <br>
                                    <el-button size="mini" type="primary" plain @click="openImgLibrary('bg')">图片</el-button>
                                    <el-button size="mini" type="success"  @click="setPageColor('green')" style="margin-left: 4px;">&nbsp;</el-button>
                                    <el-button size="mini"  @click="setPageColor('white')" style="margin-left: 4px;">&nbsp;</el-button>
                                </div>
                            </div>
                        </el-form>                   
                    </div>
                </el-drawer>
                <!--音频设置-->
                <el-drawer
                    title=""
                    :modal="false"
                    @open="openRightNav(3)"
                    @close="closeRightNav"
                    size="320px"
                    class="drawer"
                    :visible.sync="drawerAudio"
                    :with-header="false">
                    <h3>音频设置</h3>
                    <div class="drawer-warp">    
                        <el-form ref="form" :model="formAudioSet" label-width="80px" size="small">
                            <el-form-item label="语速：" class="white-label" style="display: none;">
                                <el-col :span="19">
                                    <el-select class="input-black" v-model="formAudioSet.yusu" placeholder="正常">
                                        <el-option label="正常" value="shanghai"></el-option>
                                        <el-option label="快速" value="beijing"></el-option>
                                    </el-select>
                                </el-col>
                            </el-form-item>
                            <el-form-item label="规则：" class="white-label" style="display: none;">
                                <el-col :span="19">
                                    <el-select class="input-black" v-model="formAudioSet.guize" placeholder="按字数">
                                        <el-option label="按字数" value="shanghai"></el-option>
                                        <el-option label="区域二" value="beijing"></el-option>
                                    </el-select>    
                                </el-col>
                            </el-form-item>
                            
                            <ul class="timbre-list">
                                选择音色：<br>
                                <li v-for="(item,i) in timbresList" @click="timbreSelect(i)" :class="[item.TimbreDesc==''?'vip':'',timbreCur==i?'cur':'']" :style="item.TimbreName.search('优优') !=-1?'order:1':'order=2'">
                                    <template v-if="item.TimbreDesc.search('男') !=-1 || item.TimbreName.search('男') !=-1 || ['tts_male_11','tts_male_15','tts_male_16','tts_male_35','tts_male_36'].includes(item.TimbreKey)">
                                        <em class="icon-male" style="order:0;"></em>
                                    </template>
                                    <template v-if="item.TimbreDesc.search('女') !=-1 || item.TimbreName.search('女') !=-1 || ['tts_female_45','tts_female_47','tts_female_26'].includes(item.TimbreKey)">
                                        <em class="icon-female" style="order:0;"></em>
                                    </template>
                                    <template v-if="item.TimbreName.search('童声') !=-1 || item.TimbreName.search('童声') !=-1">
                                        <em class="icon-child" style="order:0;"></em>
                                    </template>
                                    <template v-if="item.TimbreName.search('优优') !=-1">
                                        <em class="icon-vip" style="order: 1;"></em>
                                    </template>
                                    <p>{{item.TimbreName}}</p>
                                    <p style="display: none">{{item.TimbreKey}}</p>
                                    <font>{{item.TimbreDesc==""?'定制音色':item.TimbreDesc}}</font>
                                    <i class="el-icon-video-play btn-play" @click.stop="timbrePlay(i)"></i>
                                </li>
                            </ul> 
                        </el-form>
                    </div>
                </el-drawer>
                <!--图库-->
                <el-drawer
                    title=""
                    :modal="false"
                    size="320px"
                    class="drawer"
                    @open="openRightNav(4)"
                    @close="closeRightNav"
                    :visible.sync="drawerImg"
                    :with-header="false">
                    <div style="height:100%;display:flex;flex-direction:column">
                        <div class="drawer-head">
                            <el-row>
                                <el-col :span="12"><h3>素材</h3></el-col>
                                <el-col :span="12" style="text-align: right;">
                                    <el-upload ref="uploadEl" class="btn-upload" :action="uploadUrl" :on-exceed="handleExceed" multiple :limit="1" :http-request="uploadFile" :file-list="fileList" :before-upload="beforUpload">
                                        <el-button size="mini" type="primary"><i class="el-icon-upload"></i> 上传</el-button>
                                    </el-upload>
                                </el-col>
                            </el-row>
                        </div>
                        
                        <div class="drawer-warp">
                            <el-tabs v-model="libraryTabCur" class="libraryTap" @tab-click="libraryTabClick">
                                <el-tab-pane label="图片" name="libraryImg" class="libraryTapCon">
                                    <div class="img-library">
                                        <ul>
                                            <li v-for="(item,i) in uploadImgList" >
                                                <img :src="item.url+item.thumbnail_name" width="100"/>
                                                <span class="btn-del" @click="delFile(item.key,1)"><i class="el-icon-delete"></i></span>
                                                <div class="tool">
                                                    <el-tag v-show="fileCurTypeTemp ==='bg'? false : true" class="btn" @click="fileSelect(i,'logo')">{{ fileCurTypeTemp ==='logo' ? "设为":""}}Logo</el-tag>
                                                    <el-tag v-show="fileCurTypeTemp ==='logo'? false : true" class="btn" @click="fileSelect(i,'bg')">{{ fileCurTypeTemp ==='bg' ? "设为":""}}背景</el-tag>
                                                </div>
                                               
                                            </li>
                                            <br clear="all"/>
                                        </ul>
                                    </div>
                                </el-tab-pane>
                                <el-tab-pane label="视频" name="libraryVideo" class="libraryTapCon">
                                    <div class="img-library">
                                        <ul>
                                            <li v-for="(item,i) in uploadVideoList" >
                                                <img :src="item.url+item.thumbnail_name" width="100"/>
                                                <span class="btn-del" @click="delFile(item.key,0)"><i class="el-icon-delete"></i></span>
                                                <div class="tool">
                                                    <el-tag v-show="fileCurTypeTemp ==='pianwei'? false : true" class="btn" @click="fileSelect(i,'piantou')">{{ fileCurTypeTemp ==='piantou' ? "设为":""}}片头</el-tag>
                                                    <el-tag v-show="fileCurTypeTemp ==='piantou'? false : true" class="btn" @click="fileSelect(i,'pianwei')">{{ fileCurTypeTemp ==='pianwei' ? "设为":""}}片尾</el-tag>
                                                </div>
                                               
                                            </li>
                                            <br clear="all"/>
                                        </ul>
                                    </div>
                                </el-tab-pane>
                                <el-tab-pane label="背景素材" name="libraryPublic" class="libraryTapCon">
                                    <div class="img-tag">
                                        <ul>
                                           <li v-for="(item,i) in BGType" @click="getBGList(item.name,1,i)" :class="['',BGTypeCur==i?'cur':'']">{{item.describe}}</li>
                                           &nbsp;&nbsp;&nbsp;<el-checkbox v-model="BGblur" @change="changeBGblur" style="float:right;margin-right: 20px;">模糊背景</el-checkbox>
                                        </ul>
                                    </div>
                                    <div class="img-library">
                                        <ul>
                                            <li v-for="(item,i) in BGList.wallpapers" >
                                                <img :src="item.url_thumbnail" width="100"/>
                                                <div class="tool">
                                                    <el-tag class="btn" @click="fileSelect(i,'builtinBg')">设为背景</el-tag>
                                                </div>
                                               
                                            </li>
                                            <br clear="all"/>
                                        </ul>
                                    </div>
                                </el-tab-pane>
                                
                            </el-tabs>
                        </div>
                    </div>
                </el-drawer>
            </div>
        </div>
    </div>
     <!--视频预览-->
     <el-dialog
        title="视频预览"
        :visible.sync="dialogVideoPlay"
        width="652px"
        :before-close="stopPlay"
        class="videoPlayBox">
        <video ref="videoPlay" :src="videoPlayUrl" autoplay controls width="640" height="360"></video>
    </el-dialog>
</div>
</template>
<script>
import VueDragResize from 'vue-drag-resize'
import html2canvas from 'html2canvas'
export default {
    name: 'Drag',
    components: {
    VueDragResize,
    html2canvas
  },
  data() {
    return {
      vw: 0,
      vh: 0,
      top: 0,
      left: 0,
      textarea: '',
      drawerHuman:false,
      drawerVideo:false,
      drawerAudio:false,
      drawerImg:false,
      dialogVideoPlay:false,
      dialogImgLibraryLVisible:false,
      projectid:'',
      titleEditState:false,
      rightNavCurIndex:'',
      fileCurTypeTemp:'',
      saveTemp:0,
      pageViewCur:0,
      humanCur:0,
      timbreCur:0,
      videoType:'0',
      title:'',
      thumbnailUrl:'',
      save:0,//保存状态 0未保存，1已保存 3保存中 4保存成功
      makeWarning:0,
      delWarning:0,
      uploadUrl:'',//文件上传地址
      fileList:[],
      //视频区和编辑区、大小、缩放和边距
      videoResolution:'1920x1080',
      videoWidth: 0,
      videoHeight: 0,
      videoZoomW:1920,
      videoZoomH:1080,
      videoZoom:1,
      videoZoomMarginL:'0px',
      videoZoomMarginT:'0px',
      //视频区logo和数字人初始宽度
      videoLogoUrl:'',
      videoLogoY:80,
      videoLogoX:80,
      videoLogoScale:1,
      videoLogoWidth:200,
      videoLogoHeight:200,
      videoHumanUrl:'',
      videoHumanY:80,
      videoHumanX:1100,
      videoHumanScale:1,
      videoHumanWidth:600,
      videoHumanHeight:784,
      //音色
      timbreUrl:'',
      //TTS
      auditionUrl:'',
      videoBackgroundUrl:'',
      videoText:'',
      videoCurActor:'',//素材tab选择
      dragResizeKey:'',//解决缩放元素缓存问题

      audioSpeed:1,
        value: '',
        projectid:'',
        libraryTabCur:'libraryImg',//素材图默认选中
        videoHeadFileImg:'',//片头封面
        videoTailFileImg:'',//片伟封面
        videoPlayUrl:'',
        backgroundImg:{
            green:'http://49.232.143.40/human/background-green.png',
            white:'http://49.232.143.40/human/background-white.png',
        },
        //弹窗
        dialogVideoConfig:false,
        //视频设置数据
        formVideoSet:{
            postionX:'',
            postionY:'',
            postionS:'',
            postionR:'',
        },
        //音频设置数据
        formAudioSet:{
            yusu:'',
            guize:''
        },
        
        humanList:[],//数字人列表
        timbresList:[
            {
                TimbreDesc:'',
                TimbreKey:'',
                TimbreName:'',
                TimbreSample:''
            }
        ],//语音列表
        uploadImgList:[],//上传图片列表
        uploadImgListFirstLoad:false,
        uploadVideoList:[],//上传视频列表
        uploadVideoListFirstLoad:false,
        BGType:[],//背景素材列表
        BGTypeCur:0,
        BGList:[],
        BGblur:false,
    
        //缩略图列表
        pageViewList:{
            videoHeadFileUrl:'',
            videoHeadFileImg:'',
            videoTailFileUrl:'',
            videoTailFileImg:'',
            pages:[{
                virtualmanKey:'',
                backgroundFileUrl:'http://49.232.143.40/human/background-white.png',
                text:"",
                logoParams:[{
                        fileUrl:'',
                        width:200,
                        height:200,
                        positionX:-3000,
                        positionY:-3000,
                        scale: 1.0
                    }],
                humanParams:[{
                        fileUrl:'',
                        width: 480,
                        height: 660,
                        positionX: 1100,
                        positionY: 80,
                        scale: 1,
                        originZoom:1
                    }],
                timbreParams:[{
                        timbreKey:'',
                        timbreSample:'',
                        timbreSpeed:1
                    }]
                }]
        },
        //海报编辑
        videoTypeOption:[
            {
                value:'0',
                label:'16:9'
            },
            {
                value:'1',
                label:'9:16'
            }
        ]
    }
  },
  mounted() {
    this.setVideoSize()
  },
  beforeUnmount() {
    this.setVideoSize()
  },
  computed: {
    getAspectRatioStyle() {
      return {
        width: `${this.videoWidth}px`,
        height: `${this.videoHeight}px`,
        margin: 'auto',
        background: 'white'
      };
    }
    },
    created(){
        //使用创建布局
        this.$emit('hideTopbar');
        //查询
        this.projectid=this.$route.query.projectid
        
        if(this.projectid==undefined){
            //无ID终止
            this.$message({
                message: '参数错误',
                type: 'error'
            });
            return false;
        }else{
            this.getProject()
            console.log("读取项目")
        }

        //页面加载完后执行
        this.$nextTick(() => {
            //读取封面1到视频区
            this.getHuman()
        })
        
        window.addEventListener("resize", this.setVideoSize);

        
    },

  methods: {
    async getProject(){
        //接口---获取项目信息
        const {data:res} = await this.$http.post('/get/project',{
                sid:sessionStorage.getItem("token"),
                projectid:this.projectid
            })
        //获取成功
        if(res.status=='200'){
            if(res.projects[0].details!=''){
                this.saveTemp=0
                this.thumbnailUrl=res.projects[0].thumbnail_url
                this.pageViewList=JSON.parse(res.projects[0].details)
            }
            this.title=res.projects[0].name
            //选择第一页
            this.pageViewSelect(0)
            //无封面截取封面
            if (this.thumbnailUrl==''){
                this.getThumbnail()
            }
            
        }
        //获取失败
        else{
        //重试10次
        this.saveTemp=this.saveTemp+1
            if(this.saveTemp<10){
                setTimeout(() => {
                    this.getProject()
                }, 1000);
            }else{
                this.$message({
                    message: '获取失败',
                    type: 'error'
                });
                this.saveTemp=0
            }
        }
    },
    async makeProject(){
        //制作视频
        this.makeWarning=1
        const l=this.pageViewList.pages.length
        console.log(l)
        
        for (let i=0; i<l; i++) {
            if(this.pageViewList.pages[i].text==''){
                this.$message({
                    message: '部分页面未输入讲解内容，请添加后再合成视频',
                    type: 'error'
                }); 
                return false
            }
        }
        
        const result = await this.saveVideo();
        console.log(result)
        if(result==='success'){
            this.$router.push({path:'/projects/make',query:{projectid:this.projectid}})
        }else{
            this.$message({
                message: '保存失败',
                type: 'error'
            }); 
        }
        
    },
    async saveVideo(type){
        this.save=3
        //更新截图
        this.getThumbnail()
        //接口---保存项目
        const {data:res} = await this.$http.post('/update/project',{
                sid:sessionStorage.getItem("token"),
                projectid:this.projectid,
                name:this.title,
                details:JSON.stringify(this.pageViewList),
                thumbnail_url:this.thumbnailUrl
            })
            //获取成功
            switch(res.status){
                //获取成功
                case 200:
                    console.log(this.pageViewList)
                    this.save=4
                    setTimeout(() => {
                        this.save=0
                    }, 3000)
                    return 'success'
                break
                case 401:
                    this.$message.error('登录已过期'); 
                    this.$router.push("/login")
                break

                //获取失败
                default:
                    this.$message({
                        message: '保存失败',
                        type: 'error'
                    }); 
                    return 'fail'
            }
        },
        //生成缩略图
        async getThumbnail(){
            html2canvas(
                document.getElementById('edit-cover'),
                {
                backgroundColor:null,//画出来的图片有白色的边框,不要可设置背景为透明色（null）
                useCORS: true,//支持图片跨域
                scale:0.7,//设置放大的倍数
                }
            ).then(canvas => {
                //截图用img元素承装，显示在页面的上
                let img = new Image();
                img.crossOrigin = '*'; // 图片处理跨域
                img.src = canvas.toDataURL('image/jpeg');// toDataURL :图片格式转成 base64

                const fileInput = this.DataURIToBlob(img.src)

                //上传
                const formData = new FormData();
                formData.append('file', fileInput);
                var xhr =new XMLHttpRequest();
                
                xhr.open('POST','http://49.232.143.40:8089/?action=upload&filename=封面&cid=封面&sid='+sessionStorage.getItem("token")+'&ext=jpg&type=2&thumbnail=false&fileid='+this.thumbnailUrl)
                xhr.onload  = () => {
                    if(xhr.status ===200){
                        console.log('onreadystatechange success'+xhr.status +' '+xhr.responseText);
                        this.thumbnailUrl=JSON.parse(xhr.responseText).data.key;
                        console.log("缩略图生成成功:"+this.thumbnailUrl)
                        
                    }else {
                        console.log("缩略图生成失败")
                    }
                }
                xhr.send(formData);
                
                    //如果你需要下载截图，可以使用a标签进行下载
                    /*
                    let a = document.createElement('a');
                    a.href = canvas.toDataURL('image/jpeg');
                    a.download = 'test';
                    a.click();
                    */
                })
                return 'success'
            },
            //base64转
            DataURIToBlob(dataURI) {
                const splitDataURI = dataURI.split(',')
                const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
                const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

                const ia = new Uint8Array(byteString.length)
                for (let i = 0; i < byteString.length; i++)
                    ia[i] = byteString.charCodeAt(i)

                return new Blob([ia], { type: mimeString })
            },
    async getHuman(){
        //接口---获取数字人资产
        const {data:res} = await this.$http.post('/get/assets',{
                sid:sessionStorage.getItem("token"),
            })
            
            switch(res.status){
                //获取成功
                case 200:
                    console.log("数字人数据")
                    console.log(res)
                    //数字人获取----------------
                    for (let key in res.anchors) {
                        let tempHuman = {
                            VirtualmanKey:res.anchors[key].VirtualmanKey,
                            HeaderImage:res.anchors[key].HeaderImage,
                            ThumbnailUrl:'http://49.232.143.40/human/'+res.anchors[key].VirtualmanKey+'.jpg',
                            AnchorName:res.anchors[key].AnchorName,
                            OriginZoom:res.anchors[key].OriginZoom
                        }
                        console.log(res.anchors[key].OriginZoom)
                        //找出第一条数据选择
                        if(this.pageViewList.pages[0].virtualmanKey === res.anchors[key].VirtualmanKey){
                            this.humanCur=key
                        }
                        //判断横竖屏-写入数组
                        if(this.videoResolution==res.anchors[key].Resolution){
                            this.humanList.splice(key,0,tempHuman)
                        }
                        
                        console.log("写入后的数据")
                        console.log(this.humanList)
                    }

                    //音色获取----------------
                    for (let key in res.timbres) {
                        let tempTimbres = {
                            TimbreKey:res.timbres[key].TimbreKey,
                            TimbreName:res.timbres[key].TimbreName,
                            TimbreSample:res.timbres[key].TimbreSample,
                            TimbreDesc:res.timbres[key].TimbreDesc
                        }
                        //找出第一条数据选择
                        if(this.pageViewList.pages[0].timbreParams[0].timbreKey === res.timbres[key].TimbreKey){
                            this.timbreCur=key
                            this.timbreUrl=res.timbres[key].TimbreSample
                        }
                        //写入数组
                        //if(tempTimbres.TimbreName.search('优优') !=-1){
                         //   this.timbresList.splice(0,0,tempTimbres)
                        //}else{
                        //    this.timbresList.splice(key,0,tempTimbres)
                        //}
                        this.timbresList.splice(key,0,tempTimbres)
                        //console.log("音色：")
                        //console.log(this.timbresList)
                    }

                    //第一页如果ID为空判断未创建，赋予初始值
                    if(this.pageViewList.pages[0].virtualmanKey===''){
                        this.pageViewList.pages[0].virtualmanKey=this.humanList[0].VirtualmanKey
                        this.pageViewList.pages[0].humanParams[0].fileUrl=this.humanList[0].HeaderImage
                        this.pageViewList.pages[0].humanParams[0].originZoom=this.humanList[0].OriginZoom
                        this.pageViewList.pages[0].timbreParams[0].timbreKey=this.timbresList[0].TimbreKey
                        this.pageViewList.pages[0].timbreParams[0].timbreSample=this.timbresList[0].TimbreSample
                        this.pageViewList.pages[0].timbreParams[0].timbreSpeed=1
                        //设置第一个数字人和音色
                        this.humanSelect(0)
                        this.timbreSelect(0)
                        setTimeout(() => {
                            this.saveVideo()
                        }, 2000); 
                    }
                    console.log(this.pageViewList)
                break

                //登录失效
                case 401:
                    //this.$message.error('登录已过期'); 
                    //this.$router.push("/login")
                break

                //获取失败
                default:
                    console.log(res)
                    setTimeout(() => {
                        this.getHuman()
                    }, 1000)
                    console.log('获取形象失败')
                break
            }
        },
    //获取文件
    async getFile(fileType){
        console.fileType
        //接口---获取上传文件列表
        const {data:res} = await this.$http.post('http://49.232.143.40:8089/?sid='+sessionStorage.getItem("token")+'&action=query&type='+fileType,{
            })
            //获取成功
            if(res.status=='200'){
                switch(fileType){
                    case 1:
                        this.uploadImgList=res.files
                        this.uploadImgListFirstLoad=true
                        console.log(this.uploadImgList)
                    break
                    case 0:
                        this.uploadVideoList=res.files
                        this.uploadVideoListFirstLoad=true
                        console.log(this.uploadVideoList)
                    break
                }
            }
            //获取失败
            else{
                this.$message.error('获取背景分类失败'); 
                return 'fail'
            }
        },
    //获取背景素材分类
    async getBGType(){
        console.log("获取背景分类")
        //接口---获取上传文件列表
        const {data:res} = await this.$http.post('http://49.232.143.40:8089/get/?sid='+sessionStorage.getItem("token")+'&type=99&action=query',{
            })
            //获取成功
            if(res.status=='200'){
                if(this.videoResolution=='1920x1080'){
                    this.BGType=res.horizontals
                }else{
                    this.BGType=res.verticals
                }
                
                console.log(this.BGType)
                this.getBGList(this.BGType[0].name,1)
                this.BGTypeCur=0
            }
            //获取失败
            else{
                this.$message.error('获取背景分类失败'); 
                return 'fail'
            }
        },
    //获取背景素材
    async getBGList(style,page,i){
        console.log("获取背景素材:"+style)
        this.BGTypeCur=i
        //接口---获取上传文件列表
        const {data:res} = await this.$http.post('http://49.232.143.40:8089/get/?sid='+sessionStorage.getItem("token")+'&layout=horizontal&type=100&action=query&style='+style+'&blur='+this.BGblur,{
            page:page,
            page_count:50
            })
            //获取成功
            if(res.status=='200'){
                this.BGList=res
                console.log(this.BGList)
            }
            //获取失败
            else{
                this.$message.error('获取背景失败'); 
                return 'fail'
            }
    },
    //背景模糊切换
    changeBGblur(){
        if(this.BGblur){
            this.BGblur=true
            console.log("背景模糊")
        }else{
            this.BGblur=false
            console.log("背景清晰")
        }
        console.log()
        this.getBGList(this.BGType[this.BGTypeCur].name,1,this.BGTypeCur)
        
    },
    //文件上传EL
    async uploadFile(upFile){
        const checkType=['image/jpg','image/jpeg','image/png','image/gif','video/mp4']
        const fileInput = upFile.file
        if (fileInput) {
            //文件类型支持判断
            if(!checkType.includes(fileInput.type)){
                this.$message.error('不支持该文件格式，请上传jpg,jpeg,png,gif,mp4文件');
                return false
            }
            //判断大小和分类
            let fileType=1
            if(fileInput.type==='video/mp4'){
                fileType=0
                if(fileInput.size>500000000){
                    this.$message.error('视频文件不能大于500MB');
                    return false    
                }
            }else{
                if(fileInput.size>5000000){
                    this.$message.error('图片文件不能大于5MB');
                    return false  
                }
            }
            //分离文件名和后缀
            const fileName = fileInput.name.split('.')   
            const ext=fileName[fileName.length - 1];
            //上传
            const formData = new FormData();
            formData.append('file', fileInput);
            var xhr =new XMLHttpRequest();
            //加载动画
            const loading = this.$loading({
                lock: true,
                text: '文件上传中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            xhr.open('POST','http://49.232.143.40:8089/?action=upload&filename='+fileName[0]+'&cid='+fileName[0]+'&sid='+sessionStorage.getItem("token")+'&ext='+ext+'&type='+fileType+'&thumbnail=true&fileid=""')
            xhr.onload  = () => {
                if(xhr.status ===200){
                    loading.close();
                    this.$message.success('文件上传成功');
                    this.uploadImgListFirstLoad=false
                    this.getFile(fileType)
                    console.log('onreadystatechange success'+xhr.status +' '+xhr.responseText);
                }else {
                    loading.close();
                    this.$message.error('文件上传失败');
                    //console.log('onreadystatechange fialed'+xhr.status +'  '+xhr.responseText);
                }
            }
            xhr.send(formData);
        }
        
    },
    handleExceed(files, fileList) {
        this.$message.error("只能选择一个文件");
      },
    beforUpload(){
        this.$refs.uploadEl.clearFiles()
      },
    //删除文件
    async delFile(key,type){
        console.log(key)
        const {data:res} = await this.$http.post('http://49.232.143.40:8089/?sid='+sessionStorage.getItem("token")+'&action=delete&type='+type,{
            files:[key],
        })
        //获取成功
        switch(res.status){
            //获取成功
            case 200:
                this.$message.success('删除成功'); 
                this.getFile(type)
            break
            case 401:
                this.$message.error('登录已过期'); 
            break

            //获取失败
            default:
            this.$message.error('删除失败'); 
        }
    },
    setVideoSize(){
        //视频区宽：视口宽-86-280-80
        //视频区高：视口高-60-300
        const videoBoxWidth = window.innerWidth-86-280-80;
        const videoBoxHeight = window.innerHeight-60-332;
        //视频区比例
        const videoBoxProportion=videoBoxHeight/videoBoxWidth;
        if(videoBoxWidth>1920 && videoBoxHeight>1080){
            this.videoZoom=1
        }else{
            if (videoBoxProportion>=0.56){
                //竖行区域
                this.videoZoom=(videoBoxWidth-100)/1920
            }else{
                //横形区域
                this.videoZoom=(videoBoxHeight-100)/1080
            }
                //编辑区尺寸和边距
                this.videoZoomMarginT=(videoBoxHeight-1080*this.videoZoom)/2*(2.1+this.videoZoom)+'px'
                this.videoZoomMarginL=(videoBoxWidth-1920*this.videoZoom)/2*(2.1+this.videoZoom)+'px'
                console.log(1920*(1-this.videoZoom))     
        }
    },
       
        
    //视频编辑区尺寸计算
    calculateAspectRatio() {
      const container = document.querySelector('.video-edit-box');
      const containerWidth = container.offsetWidth;
      const containerHeight = container.offsetHeight;
      console.log('宽：'+containerWidth);
      console.log('高：'+containerHeight);
 
      const aspectRatio = 16 / 9; // 16:9 比例
      const containerAspectRatio = containerWidth / containerHeight;
 
      if (containerAspectRatio > aspectRatio) {
        // 以高度为基准，按比例计算宽度
        this.videoHeight = containerHeight;
        this.videoWidth = Math.floor(containerHeight * aspectRatio);
      } else {
        // 以宽度为基准，按比例计算高度
        this.videoWidth = containerWidth;
        this.videoHeight = Math.floor(containerWidth / aspectRatio);
      }
    },
    //右侧导航开关高亮
    openRightNav(index){
        this.rightNavCurIndex=index;
        console.log("打开第"+index+'个')
    },
    closeRightNav(){
        this.rightNavCurIndex=''
        this.fileCurTypeTemp=''
    },
    titleEdit(){
        this.titleEditState=false

    },
    //编辑标题
    openTitleEdit(){
        this.titleEditState=true
        this.$nextTick(()=>{
            this.$refs.titleInput.focus()
        })
    },
    titleEdit(){ 
        if(this.title===''){
            const date = new Date();
            const year = date.getFullYear()
            const month = date.getMonth()+1
            const day = date.getDate()
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const seconds = date.getSeconds();
            this.title = '未命名'+year+'-'+month+'-'+day+'-'+hours+':'+minutes+':'+seconds
        }
        this.titleEditState=false
    },
    //数字人选择
    humanSelect(i){
        //dragResizeKey防止编辑器中数据缓存
        let code = '';//声明一个空的字符串值用于后面赋值
        const codeLength = 4; //验证码的长度，可以根据个人需求改变
        const random = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
          'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
        ]; //随机数的取值范围
        for (let i = 0; i < codeLength; i++) {
          //循环遍历codeLength，值是几，就循环几次
          let index = Math.floor(Math.random() * 36); //Math.floor方法用于返回小于或等于一个给定数字的最大整数；Math.random方法返回 0（含）和 1（不含）之间的随机数
          code += random[index]; //根据索引取得随机数加到code上
        }
        this.dragResizeKey = code; //把code值赋给data中定义好的checkCode

        this.humanCur=i;
        const url=this.humanList[i].HeaderImage

        // 图片尺寸计算
        var img = new Image();
        img.src = url;
        let res = {}
        img.onload = () => {
            res = {
                width: img.width,
                height: img.height
            }
            if(this.videoZoomH<res.height){
                this.pageViewList.pages[this.pageViewCur].humanParams[0].scale=1080/res.height
            }else{
                this.pageViewList.pages[this.pageViewCur].humanParams[0].scale=1
            }
            this.videoHumanUrl=this.humanList[i].HeaderImage
            this.pageViewList.pages[this.pageViewCur].humanParams[0].height=res.height
            this.pageViewList.pages[this.pageViewCur].humanParams[0].width=res.width
            this.pageViewList.pages[this.pageViewCur].virtualmanKey=this.humanList[i].VirtualmanKey
            this.pageViewList.pages[this.pageViewCur].humanParams[0].fileUrl=this.humanList[i].HeaderImage
            this.pageViewList.pages[this.pageViewCur].humanParams[0].originZoom=this.humanList[i].OriginZoom
            console.log(this.videoZoomH)
            console.log(this.videoHumanHeight)
            console.log(this.videoHumanWidth)
            this.pageViewSelect(this.pageViewCur)
            
        }
    },
    //音色选择
    timbreSelect(i){
        this.timbreCur=i
        this.timbreUrl=this.timbresList[i].TimbreSample
        this.pageViewList.pages[this.pageViewCur].timbreParams[0].timbreKey=this.timbresList[i].TimbreKey
        this.pageViewList.pages[this.pageViewCur].timbreParams[0].timbreSample=this.timbresList[i].TimbreSample
    },
    //音色试听
    timbrePlay(i){
        if(i ==''){
            //预览选中
            this.timbreUrl=this.timbresList[this.timbreCur].TimbreSample 
        }else{
            this.timbreUrl=this.timbresList[i].TimbreSample
        }
        //let timbre = new Audio(); //建立一个music1对象
       //timbre = this.timbresList[i].TimbreSample;//通过require引入音频
        //this.timbreUrl = timbre;//此处的audio为代码ref="audio"中的audio
        this.$nextTick(res => {
            this.$refs.timbrePlayer.play();//play()为播放函数
        })
        
    },
    //文本试听
    async textAudition(){
        if(this.videoText==''){
            this.$message.error('请输入内容')
            return false
        }
        const {data:res} = await this.$http.post('/tts/make/authorization',{
                sid:sessionStorage.getItem("token"),
                VoiceType:1001,
                Codec:'mp3',
                text:this.videoText.slice(0,40)
            })
            if(res.status=='200'){
                console.log(res)
                this.TxGetTextAudio(res.authorization,res.body)
            }else{

            }
    },
    //腾讯TTS
    async TxGetTextAudio(authorization,reqBody){
        console.log(reqBody)
        const {data:res} = await this.$http.post('https://tts.cloud.tencent.com/stream',reqBody,{
                headers: {
                    'Content-Type':'application/json',
                    'Authorization': authorization
                },
                responseType: 'blob'
            })
            
            if(!res.Response){
                var audioBlob = new Blob([res], { type: 'audio/mpeg' });
                var audioUrl = URL.createObjectURL(audioBlob);
                this.auditionUrl=audioUrl
                this.$nextTick(res => {
                    this.$refs.auditionPlayer.play();//play()为播放函数
                })
            }else{
                this.$message.error('试听失败'); 
            }
    },
    //语速设置
    timbreSpeedChange(val){
        this.pageViewList.pages[this.pageViewCur].timbreParams[0].timbreSpeed=val
    },
    //素材Tab点击
    libraryTabClick(tab, event){
        switch(tab.index){
            //获取视频
            case "1":
                this.openVideoLibrary(0)
            break
            //获取背景
            case "2":
                this.openBGLibrary()
            break
        }
    },
    //图库
    openImgLibrary(imgType){
        if(!this.uploadImgListFirstLoad){
            this.getFile(1)
        }
        this.fileCurTypeTemp=imgType //是否设置更换logo或bg
        this.drawerImg = true;
        console.log(this.fileCurTypeTemp)
    },
    //视频
    openVideoLibrary(videoType){
        
        if(!this.uploadVideoListFirstLoad){
            this.getFile(0)
            console.log(this.uploadVideoListFirstLoad)
        }
        this.fileCurTypeTemp=videoType //是否设置片头或片尾
        this.drawerImg = true;
        this.libraryTabCur='libraryVideo'
        console.log("视频页签")
    },
    //背景
    openBGLibrary(){
        this.getBGType()
    },

    //素材选择
    fileSelect(i,fileType){
        console.log(this.uploadImgList)
            switch(fileType){
                //logo选择
                case "logo":
                    // 图片尺寸计算
                    var img = new Image();
                    img.src = this.uploadImgList[i].url+this.uploadImgList[i].file_name
                    let res = {}
                    img.onload = () => {
                        res = {
                            width: img.width,
                            height: img.height
                        }
                        //判断当前是否有logo
                        if(this.pageViewList.pages[this.pageViewCur].logoParams[0].fileUrl===''){
                            const scale=400/res.width
                            this.videoLogoY=80
                            this.videoLogoX=80
                            this.videoLogoWidth=400
                            this.pageViewList.pages[this.pageViewCur].logoParams[0].scale=scale
                            this.pageViewList.pages[this.pageViewCur].logoParams[0].positionX=80
                            this.pageViewList.pages[this.pageViewCur].logoParams[0].positionY=80
                            //console.log('无logo')
                        }else{
                            const scale=this.videoLogoWidth/res.width
                            this.videoLogoWidth=res.width*scale
                            this.videoLogoHeight=res.height*scale
                            this.pageViewList.pages[this.pageViewCur].logoParams[0].scale=scale
                            //console.log('有logo')
                        }
                        this.videoLogoUrl=this.uploadImgList[i].url+this.uploadImgList[i].file_name
                        this.pageViewList.pages[this.pageViewCur].logoParams[0].width=res.width
                        this.pageViewList.pages[this.pageViewCur].logoParams[0].height=res.height
                        this.pageViewList.pages[this.pageViewCur].logoParams[0].fileUrl=this.uploadImgList[i].url+this.uploadImgList[i].file_name
                        this.pageViewSelect(this.pageViewCur)
                    }
                break
                //背景处理
                case "bg":
                    this.pageViewList.pages[this.pageViewCur].backgroundFileUrl=this.uploadImgList[i].url+this.uploadImgList[i].file_name
                    this.backgroundFileUrl=this.uploadImgList[i].url+this.uploadImgList[i].file_name
                    this.pageViewSelect(this.pageViewCur)
                break
                //内置背景处理
                case "builtinBg":
                    this.pageViewList.pages[this.pageViewCur].backgroundFileUrl=this.BGList.wallpapers[i].url_original
                    this.backgroundFileUrl=this.BGList.wallpapers[i].url_original
                    this.pageViewSelect(this.pageViewCur)
                break
                //片头处理
                case "piantou":
                    console.log("片头")
                    console.log(this.uploadVideoList)
                    this.pageViewList.videoHeadFileUrl=this.uploadVideoList[i].url+this.uploadVideoList[i].file_name
                    this.pageViewList.videoHeadFileImg=this.uploadVideoList[i].url+this.uploadVideoList[i].thumbnail_name
                    
                break
                //片尾处理
                case "pianwei":
                    this.pageViewList.videoTailFileUrl=this.uploadVideoList[i].url+this.uploadVideoList[i].file_name
                    this.pageViewList.videoTailFileImg=this.uploadVideoList[i].url+this.uploadVideoList[i].thumbnail_name
                    console.log(this.pageViewList)
                break
            
        }

        //console.log(i)
        // console.log(fileType)
        if(this.fileCurTypeTemp===""){
            this.drawerImg = true;
        }
        this.fileType=''
        this.fileCurTemp=''
        
    },
    //背景色设置
    setPageColor(color){
        switch(color){
        case "white":
            this.backgroundFileUrl=this.backgroundImg.white
            this.pageViewList.pages[this.pageViewCur].backgroundFileUrl=this.backgroundImg.white
            this.pageViewSelect(this.pageViewCur)
        break
        case "green":
            this.backgroundFileUrl=this.backgroundImg.green
            this.pageViewList.pages[this.pageViewCur].backgroundFileUrl=this.backgroundImg.green
            this.pageViewSelect(this.pageViewCur)
        break
        }
    },
    //删除片头片尾
    pageVideoDel(videoType){
        switch(videoType){
            //删除片头
        case "piantou":
            this.pageViewList.videoHeadFileUrl=""
            this.pageViewList.videoHeadFileImg=""
        break
        case "pianwei":
            this.pageViewList.videoTailFileUrl=""
            this.pageViewList.videoTailFileImg=""
        break
        }
    },
    //片头片尾预览
    videoPlay(fileurl) {
        console.log("关闭")
        this.videoPlayUrl=fileurl
        this.dialogVideoPlay=true
        this.$refs.videoPlay.currentTime = 0
        video.play().catch((err)=>{
            console.log(err)
        })
        this.$refs.videoPlay.play()
    },
    stopPlay() {
        console.log("关闭")
        this.$refs.videoPlay.pause()
        this.dialogVideoPlay=false
    },
    //删除图片
    delImg(imgType){
        switch(imgType){
        //logo选择
        case "logo":
            this.videoLogoUrl=''
            this.videoLogoY=-3000
            this.videoLogoX=-3000
            this.videoLogoScale=1
            this.videoLogoWidth=200
            this.videoLogoHeight=200
            this.pageViewList.pages[this.pageViewCur].logoParams[0].fileUrl=''
            this.pageViewList.pages[this.pageViewCur].logoParams[0].positionY=-3000
            this.pageViewList.pages[this.pageViewCur].logoParams[0].positionX=-3000
            this.pageViewList.pages[this.pageViewCur].logoParams[0].scale=1
            this.pageViewList.pages[this.pageViewCur].logoParams[0].width=200
            this.pageViewList.pages[this.pageViewCur].logoParams[0].height=200
        break
        case "bg":
            this.backgroundFileUrl=this.backgroundImg.white
            this.pageViewList.pages[this.pageViewCur].backgroundFileUrl=this.backgroundImg.white
            this.pageViewSelect(this.pageViewCur)
        break
        }
    },
    //视频区元素选择
    curActor(actor){
       this.videoCurActor=actor 
    },
    //保存文本
    saveVideoText(event){
        this.pageViewList.pages[this.pageViewCur].text=this.videoText
    },
    //缩略图选择事件
    pageViewSelect(i){
        let code = '';//声明一个空的字符串值用于后面赋值
        const codeLength = 4; //验证码的长度，可以根据个人需求改变
        const random = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
          'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
        ]; //随机数的取值范围
        for (let i = 0; i < codeLength; i++) {
          //循环遍历codeLength，值是几，就循环几次
          let index = Math.floor(Math.random() * 36); //Math.floor方法用于返回小于或等于一个给定数字的最大整数；Math.random方法返回 0（含）和 1（不含）之间的随机数
          code += random[index]; //根据索引取得随机数加到code上
        }
        this.dragResizeKey = code; //把code值赋给data中定义好的checkCode
        
        this.videoText=this.pageViewList.pages[i].text

        this.pageViewCur=i;
        this.videoBackgroundUrl=this.pageViewList.pages[i].backgroundFileUrl
        
        //logo参数
        let logoUrl=this.pageViewList.pages[i].logoParams[0].fileUrl
        let logoY=this.pageViewList.pages[i].logoParams[0].positionY
        let logoX=this.pageViewList.pages[i].logoParams[0].positionX
            //logo为空
        if(logoUrl==''){
            logoUrl=''
            logoY=-3000
            logoX=-3000
        }
        this.videoLogoUrl=logoUrl
        this.videoLogoY=logoY
        this.videoLogoX=logoX
        this.videoLogoX=this.pageViewList.pages[i].logoParams[0].positionX
        this.videoLogoScale=this.pageViewList.pages[i].logoParams[0].scale
        this.videoLogoWidth=this.pageViewList.pages[i].logoParams[0].width*this.pageViewList.pages[i].logoParams[0].scale
        this.videoLogoHeight=this.pageViewList.pages[i].logoParams[0].height*this.pageViewList.pages[i].logoParams[0].scale
        
        //数字人参数
        this.videoHumanUrl=this.pageViewList.pages[i].humanParams[0].fileUrl
        this.videoHumanY=this.pageViewList.pages[i].humanParams[0].positionY
        this.videoHumanX=this.pageViewList.pages[i].humanParams[0].positionX
        this.videoHumanScale=this.pageViewList.pages[i].humanParams[0].scale
        this.videoHumanWidth=this.pageViewList.pages[i].humanParams[0].width*this.pageViewList.pages[i].humanParams[0].scale
        this.videoHumanHeight=this.pageViewList.pages[i].humanParams[0].height*this.pageViewList.pages[i].humanParams[0].scale
        //console.log(this.pageViewList[i].logoParams[0].fileUrl)

        //设置当前数字人在列表中选择状态
        //console.log(this.pageViewList[i].virtualmanKey)
        const humanIndex = this.humanList.findIndex((item) => item.VirtualmanKey === this.pageViewList.pages[i].virtualmanKey);
        this.humanCur=humanIndex;
        

        //音色参数 
        this.timbreUrl = this.pageViewList.pages[i].timbreParams[0].timbreSample
        this.timbreSpeed = this.pageViewList.pages[i].timbreParams[0].timbreSpeed
        const timbreIndex = this.timbresList.findIndex((item) => item.TimbreKey === this.pageViewList.pages[i].timbreParams[0].timbreKey);
        this.timbreCur=timbreIndex;
        console.log("当前音色：")
        console.log(this.pageViewList.pages[i].timbreParams[0].timbreKey)


    },
    //视频区LOGO和数字人缩放
    resize(newRect,curImg) {
        if(curImg=="logo"){
            //this.pageViewList[this.pageViewCur].logoParams[0].width = newRect.width;
            //this.pageViewList[this.pageViewCur].logoParams[0].height = newRect.height;
            this.pageViewList.pages[this.pageViewCur].logoParams[0].positionY= newRect.top;
            this.pageViewList.pages[this.pageViewCur].logoParams[0].positionX = newRect.left;
            this.pageViewList.pages[this.pageViewCur].logoParams[0].scale=newRect.width/this.pageViewList.pages[this.pageViewCur].logoParams[0].width;
            this.videoLogoScale=newRect.width/this.pageViewList.pages[this.pageViewCur].logoParams[0].width;
            this.videoLogoY= newRect.top;
            this.videoLogoX= newRect.left;
        }else if(curImg=="human"){
            //this.pageViewList[this.pageViewCur].humanParams[0].width = newRect.width;
            //this.pageViewList[this.pageViewCur].humanParams[0].height = newRect.height;
            this.pageViewList.pages[this.pageViewCur].humanParams[0].positionY= newRect.top;
            this.pageViewList.pages[this.pageViewCur].humanParams[0].positionX = newRect.left;
            this.pageViewList.pages[this.pageViewCur].humanParams[0].scale=newRect.width/this.pageViewList.pages[this.pageViewCur].humanParams[0].width;
            this.videoHumanScale=newRect.width/this.pageViewList.pages[this.pageViewCur].humanParams[0].width;
            this.videoHumanY= newRect.top;
            this.videoHumanX= newRect.left;
        }
        this.videoCurActor=curImg 
        console.log(newRect)
        console.log(curImg)  
    },
    //视频分页操作
    pageViewDown(i){
        let temp = this.pageViewList.pages[i]
        this.$set(this.pageViewList.pages,i,this.pageViewList.pages[i+1])
        this.$set(this.pageViewList.pages,i+1,temp)
    },
    pageViewUp(i){
        let temp = this.pageViewList.pages[i]
        this.$set(this.pageViewList.pages,i,this.pageViewList.pages[i-1])
        this.$set(this.pageViewList.pages,i-1,temp)
    },
    pageViewDel(i){
        let temp = this.pageViewList.pages.indexOf(i);
        this.pageViewList.pages.splice(i, 1);
        if(i===0){
            this.pageViewCur=0
        }else if(i<=this.pageViewCur){
            this.pageViewCur=i-1
        }
        this.pageViewSelect(this.pageViewCur)
        console.log(this.pageViewCur)
    },
    pageViewAdd(i){
        if(i===-1){
            console.log("对了")
            this.pageViewCopy(this.pageViewList.pages.length-1)
        }else{
            this.pageViewCopy(i)
        }
        this.delImg('logo')
        this.delImg('bg')
        this.pageViewList.pages[this.pageViewCur].text=''
    },
    //复制页面
    pageViewCopy(i){
        const temp = {
            virtualmanKey:this.pageViewList.pages[i].virtualmanKey,
            backgroundFileUrl:this.pageViewList.pages[i].backgroundFileUrl,
            text:this.pageViewList.pages[i].text,
            logoParams:[{
                    fileUrl:this.pageViewList.pages[i].logoParams[0].fileUrl,
                    width:this.pageViewList.pages[i].logoParams[0].width,
                    height:this.pageViewList.pages[i].logoParams[0].height,
                    positionX:this.pageViewList.pages[i].logoParams[0].positionX,
                    positionY:this.pageViewList.pages[i].logoParams[0].positionY,
                    scale:this.pageViewList.pages[i].logoParams[0].scale
                }],
            humanParams:[{
                    fileUrl:this.pageViewList.pages[i].humanParams[0].fileUrl,
                    width: this.pageViewList.pages[i].humanParams[0].width,
                    height: this.pageViewList.pages[i].humanParams[0].height,
                    positionX:this.pageViewList.pages[i].humanParams[0].positionX,
                    positionY:this.pageViewList.pages[i].humanParams[0].positionY,
                    scale: this.pageViewList.pages[i].humanParams[0].scale,
                    originZoom: this.pageViewList.pages[i].humanParams[0].originZoom
                }],
            timbreParams:[{
                    timbreKey:this.pageViewList.pages[i].timbreParams[0].timbreKey,
                    timbreSample:this.pageViewList.pages[i].timbreParams[0].timbreSample,
                    timbreSpeed:this.pageViewList.pages[i].timbreParams[0].timbreSpeed
                }]
        }
        this.pageViewList.pages.splice(i,0,temp)
        this.pageViewCur=i+1
        this.pageViewSelect(this.pageViewCur)
        console.log(this.pageViewCur)
        //deepClone(this.pageViewList[i])

    },
    pageViewEdit(i){
        this.pageViewCur=i;
    },
      changeItem(){
        console.log('eee')
      }

  }
}
</script>
<style lang="less" scoped>
    .creat-page{height: 100%;}
    .header-creat{height: 60px;background-color: #242438;padding: 0 16px;}
    .header-creat h2{color:#ffffff;font-size:16px;line-height: 60px;text-indent:8px;}
    .header-creat .creat-toolbar{height: 60px;display: flex;justify-content:flex-end;}
    .header-creat .creat-toolbar .middle{align-self:center}
    .creat-warp{box-sizing: border-box;display: flex;height:calc(100% - 60px);}
    .creat-left{width: 280px;background:#F4F4F6;}
    .creat-left-warp{height: 100%;box-sizing: border-box;height: 100%;padding:20px;overflow: auto;}
    /*页面预览*/
    .page-list{}

    .page-list .page-video-add{border-radius:8px;border:1px dashed #aaaaaa;height:40px;margin-bottom: 16px; text-align: center;position:relative;line-height:40px;font-size: 14px;cursor: pointer;overflow:hidden;-webkit-transition-property:height margin-bottom;-webkit-transition-duration:0.5s;-webkit-transition-timing-function: ease;}
    .page-list .page-video-add:hover{color: #1989FA;border-color: #1989FA;background-color:#ecf5ff;box-shadow:0 0 6px 0px rgba(0,0,0,0.2);}
    .page-list .page-video-add .tool-bar{position: absolute;z-index:10;width:100%;bottom:-30px;background-image: linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4) );height: 28px;text-align: right;line-height:28px;color: #ffffff;border-radius:0 0 7px 7px;-webkit-transition-property:bottom;-webkit-transition-duration:0.3s;-webkit-transition-timing-function: ease;}
    .page-list .page-video-add .tool-bar i{margin-right: 8px;}
    .page-list .page-video-add .tool-bar i:hover{opacity: 0.6;cursor: pointer;}
    .page-list .page-video-add .play{position:absolute;height:100%;width:100%;z-index:10;background:rgba(0,0,0,0.2);font-size:64px;color:rgba(255,255,255,0.4);line-height:135px}
    .page-list .page-video-add .play:hover{color:rgba(255,255,255,1)}
    .page-list .page-video-import{height: 135px;-webkit-transition-property:height margin-bottom;-webkit-transition-duration:0.5s;-webkit-transition-timing-function: ease;}
    .page-list .page-video-import:hover .tool-bar{bottom:0px;-webkit-transition-property:bottom;-webkit-transition-duration:0.3s;-webkit-transition-timing-function: ease;}
    .page-list .page-video-import .btn-add{display:none}
    

    .page-list .page-view{height: 137px;margin-bottom:16px;position: relative;-webkit-transition-property:height margin-bottom;-webkit-transition-duration:0.5s;-webkit-transition-timing-function: ease;}
    .page-list .page-view .page-view-warp{height: 135px;overflow: hidden;position: relative;border-radius:8px;background: #ffffff;border: 1px solid rgba(255,255,255,0);}
    .page-list .page-view .page-view-warp .tips-warning{position: absolute;bottom:6px;left:6px;font-size: 16px;color:#ff3300;z-index:20;border:1px solid #ffffff;border-radius:20px;background-color: #ffffff;}
    .page-list .page-view .tool-bar{position: absolute;z-index:10;width:100%;bottom:-30px;background-image: linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4) );height: 28px;text-align: right;line-height:28px;color: #ffffff;border-radius:0 0 7px 7px;-webkit-transition-property:bottom;-webkit-transition-duration:0.3s;-webkit-transition-timing-function: ease;}
    .page-list .page-view .tool-bar i{margin-right: 8px;}
    .page-list .page-view .tool-bar i:hover{opacity: 0.6;cursor: pointer;}
    .page-list .page-view .cover{width:1920px;height:1080px;zoom:0.125;border-radius: 4px;overflow: hidden;background-color:#ffffff}
    .page-list .page-view .cover img{position:absolute}
    .page-list .page-view .cover img.bg{width: 100%;height: 100%;}
   
    .page-list .page-view .page-view-warp:hover{box-shadow:0 0 6px 0px rgba(0,0,0,0.2);}
    .page-list .page-view .page-view-warp:hover .tool-bar{bottom:0px;-webkit-transition-property:bottom;-webkit-transition-duration:0.3s;-webkit-transition-timing-function: ease;}
    .page-list .page-view .page-view-warp.cur{border:1px solid #1989FA ;}
    .page-list .page-view .btn-page-add{display:none;width:100%;text-align:center;padding-top:4px;}
    .page-list .page-view:not(:nth-last-child(2)):hover{height:180px;margin-bottom:0;-webkit-transition-property:height margin-bottom;-webkit-transition-duration:0.5s;-webkit-transition-timing-function: ease;}
    .page-list .page-view:not(:nth-last-child(2)):hover .btn-page-add{display:block}
    .btn-page-add{width:100%;margin:0 auto 16px auto;display: inline-block;}
    .creat-center{flex:1;background:#ffffff;overflow: auto;}
    .creat-center-warp{height: 100%;display: flex;flex-direction:column;min-height: 500px;}
    
    /*视频编辑区*/
    .creat-center-warp .video-edit-warp{flex:1;background-color:#898995;position: relative;background-position: 100% 100%;}
    .creat-center-warp .video-edit-warp h3{color: #ffffff;font-size: 14px;position: absolute;left:32px;top:24px;}
    .video-edit-box{height:100%;width:100%;overflow: auto;position: relative;}
    .video-edit-box .video-edit-con{border-radius:4px;position: absolute;overflow: hidden;width:1920px;height: 1080px;background-color: #ffffff;z-index:0;zoom:0.5;background-size: 100% 100%;}
    .video-edit-box .video-mask{border: 2000px solid rgba(137,137,149,0.5);position: absolute;z-index:10;}

    .video-edit-box .quick-tool{position:absolute;top:50%;left:16px;margin-top:-40px;border-radius:32px;padding:12px 0;width:36px;background-color:rgba(0,0,0,0.4);}
    .video-edit-box .quick-tool span{width: 36px;height:26px;display:block;margin:2px auto;background-repeat:no-repeat;background-position: center center;}
    .video-edit-box .quick-tool span:hover{background-color:rgba(25, 137, 250, 0.2);}
    .video-edit-box .quick-tool span.icon-logo{background-image: url(../assets/img/icon-quicktool-logo.svg);}
    .video-edit-box .quick-tool .bg-grounp{height:26px;overflow:hidden;text-align: center;-webkit-transition-property:height;-webkit-transition-duration:0.5s;-webkit-transition-timing-function: ease;}
    .video-edit-box .quick-tool .bg-grounp:hover{height:68px;-webkit-transition-property:height;-webkit-transition-duration:0.5s;-webkit-transition-timing-function: ease;}
    .video-edit-box .quick-tool span.icon-bg{background-image: url(../assets/img/icon-quicktool-bg.svg);}
    .video-edit-box .quick-tool span.icon-bg-green{width:16px;height:16px;background-color: #49a253;border-radius:16px;margin-bottom:4px}
    .video-edit-box .quick-tool span.icon-bg-white{width:16px;height:16px;background-color: #ffffff;border-radius:16px;}
    .video-edit-box .quick-tool span.icon-bg-green:hover,.video-edit-box .quick-tool span.icon-bg-white:hover{opacity: 0.8;}
    .creat-center-warp .text-edit-warp{height: 300px;padding:16px 32px;}
    .creat-center-warp .text-edit-warp h3{color: #333333;font-size: 14px;padding:0;}
    .creat-center-warp .text-edit-warp .top-bar{height: 32px;line-height: 32px;position: relative}
    .creat-center-warp .text-edit-warp .top-bar .btn-audio-play{position:absolute;left:4px;top:39px;z-index:10;width:24px;height:18px;line-height: 24px;text-align: center;cursor: pointer;}
    .creat-center-warp .text-edit-warp .top-bar .btn-audio-play img{height: 16px;}
    .creat-center-warp .text-edit-warp .top-bar .btn-audio-play:hover{opacity:0.6;}
    .creat-center-warp .text-edit-warp .bottom-bar{font-size:12px;margin-top:8px;}
    .creat-center-warp .text-edit-warp {}

    /*右侧工具栏*/
    .creat-right{width: 80px;background: #242438;}
    .creat-right .video-right-bar{border-top:1px solid rgba(255,255,255,0.1);}
    .creat-right .video-right-bar ul.right-nav{display: block;}
    .creat-right .video-right-bar ul.right-nav li{display: block; color:#A4A4B0;text-align: center;margin-top:32px;}
    .creat-right .video-right-bar ul.right-nav li:hover{opacity:0.6;}
    .creat-right .video-right-bar ul.right-nav li i{font-size:28px;}
    .creat-right .video-right-bar ul.right-nav li.cur{color: #1989FA;}
    .creat-right .video-right-bar .drawer-warp{padding:0 16px;height:calc(100% - 124px);overflow: auto;box-sizing: border-box;}
    .creat-right .video-right-bar h3{font-size:16px;color: #ffffff;padding:16px}
    .creat-right .video-right-bar h4{font-size:14px;color: #ffffff;padding:0 10px;background:rgba(255,255,255,0.2);border-radius:4px}

    .creat-right .video-right-bar .human-list{}
    .creat-right .video-right-bar .human-list li{display:block;padding:0 8px;box-sizing: border-box;float:left;width: 50%;position: relative;}
    .creat-right .video-right-bar .human-list li img{width: 100%;border-radius: 4px;border:1px solid rgba(255,255,255,0);margin-bottom:8px;}
    .creat-right .video-right-bar .human-list li:hover{opacity: 0.8;box-shadow:0 0 6px 0px rgba(0,0,0,0.2);}
    .creat-right .video-right-bar .human-list li.cur img{border:1px solid #1989FA;}
    .creat-right .video-right-bar .human-list li.cur::before{content:url(../assets/img/icon-select.svg);width:16px;height: 16px;position: absolute;right:16px;top: 8px;}
    .creat-right .video-right-bar .human-list li.cur:hover{opacity: 1;}
    .creat-right .video-right-bar .human-list li i{display: block;position: absolute;bottom: 19px;left: 14px;background: rgba(0, 0, 0, 0.5);color: #ffffff;padding: 2px 10px;font-size: 12px;font-style: normal;border-radius: 2px;}

    .creat-right .btn-upload{margin:16px 16px 0 0;}
    .img-tag{padding-bottom:4px;}
    .img-tag li{display: inline-block;font-size: 12px;padding:4px 8px;border-radius:4px;background-color:rgba(255,255,255,0.2);color: #dddddd;margin:0 4px 4px 0;}
    .img-tag li:hover{background-color:rgba(255,255,255,0.5)}
    .img-tag li.cur{color:#ffffff;background-color:#1989FA;}

    .img-library{height:calc(100% - 80px);overflow-y: auto;}
    .img-library li{display:flex;position: relative;flex-direction:column;justify-content:center;padding:0 8px;box-sizing: border-box;float:left;width:45%;height:136px;position: relative;border-radius: 4px;background-color:rgba(255,255,255,0.1);margin:8px 4px;overflow: hidden;}
    .img-library li img{width: 100%;border-radius: 4px;margin-bottom:8px;}
    .img-library li .btn-del{width: 24px;height: 24px;right:0;top:0;background-color: rgba(0,0,0,0.2);text-align: center;line-height: 24px;position: absolute;border-radius: 0 4px 0 4px;color: #ffffff;display: none;}
    .img-library li .btn-del:hover{background-color: #ff3300;}
    .img-library li .tool{width:calc(100% - 16px);bottom:-54px;text-align: center;position: absolute;-webkit-transition-property:bottom;-webkit-transition-duration:0.5s;-webkit-transition-timing-function: ease;}
    .img-library li .tool .btn{margin:0 2px;display: inline-block;height: 24px;line-height: 22px;}
    .img-library li .tool .btn:hover{background-color: #1989FA;color: #ffffff;border-color: #1989FA;}
    .img-library li:hover{box-shadow:0 0 6px 0px rgba(0,0,0,0.2);background-color:rgba(25, 137, 250, 0.2);}
    .img-library li:hover .btn-del{display: block;}
    .img-library li:hover .tool{bottom:8px;-webkit-transition-property:bottom;-webkit-transition-duration:0.5s;-webkit-transition-timing-function: ease;}

    .img-library li.cur{border:1px solid #1989FA;}
    .img-library li.cur::before{content:url(../assets/img/icon-select.svg);width:16px;height: 16px;position: absolute;right:16px;top: 8px;}
    .img-library li.cur:hover{opacity: 1;}

    .creat-right .video-right-bar .btn-img-add{border-radius:8px;width:90px;border:1px dashed #aaaaaa !important;height:90px;margin-bottom: 16px; text-align: center; line-height:40px;font-size:32px;cursor: pointer;background:rgba(255,255,255,0) !important;}
    .creat-right .video-right-bar .btn-img-add:hover{border:1px dashed #1989FA !important;background:rgba(255,255,255,0.1) !important}
    .creat-right .video-right-bar .btn-img-add:hover i{color:#1989FA !important}
    .creat-right .video-right-bar .logo-thumb {margin:0 0 16px 80px;}
    .creat-right .video-right-bar .logo-thumb span{display:inline-block;border-radius:4px;background-image: url(../assets/img/bg-transparent.jpg);}
    .creat-right .video-right-bar .logo-thumb span img{max-width:140px;float: left;}
    .creat-right .video-right-bar .timbre-list li{width:100px;height:48px;border: 1px dashed rgba(255,255,255,0);border-radius:8px;background:rgba(80,80,95,1);list-style: none;margin: 0 8px 8px 0;float: left;line-height:16px;color: #aaaaaa;position: relative;padding-left:32px;}
    .creat-right .video-right-bar .timbre-list li.vip{background:linear-gradient(-45deg, #775740, #866e59);border:1px solid #866e59}
    .creat-right .video-right-bar .timbre-list li:hover{border-color: #1989FA;background:rgba(255,255,255,0.1);}
    .creat-right .video-right-bar .timbre-list li:hover .btn-play{opacity:1;}
    .creat-right .video-right-bar .timbre-list li.cur{border: 1px solid #1989FA;color: #1989FA;background-color:rgba(25,137,250,0.2);}
    .creat-right .video-right-bar .timbre-list li.cur::before{content:url(../assets/img/icon-select.svg);width:16px;height: 16px;position: absolute;right:4px;top:4px;}
    .creat-right .video-right-bar .timbre-list li .btn-play{position: absolute;right:4px;bottom:4px;font-size:18px;color: #dddddd;opacity:0.4;}
    .creat-right .video-right-bar .timbre-list li .btn-play:hover{color: #1989FA;opacity:1;}
    .creat-right .video-right-bar .timbre-list li p{color:#ffffff;padding-top:8px;}
    .creat-right .video-right-bar .timbre-list li font{color:#999999;font-size:12px}
    .creat-right .video-right-bar .timbre-list li em{width:18px;height:18px;display:block;position:absolute;left:6px;top:8px;background-size:18px 18px;background-repeat:no-repeat;}
    .creat-right .video-right-bar .timbre-list li em.icon-male{background-image:url(../assets/img/icon-male.svg)}
    .creat-right .video-right-bar .timbre-list li em.icon-female{background-image:url(../assets/img/icon-female.svg)}
    .creat-right .video-right-bar .timbre-list li em.icon-child{background-image:url(../assets/img/icon-child.svg)}
    .creat-right .video-right-bar .timbre-list li em.icon-vip{background-image:url(../assets/img/icon-vip.svg)}
    /*定制样式*/

    .creat-right .video-right-bar .timbre-list li.vip p{font-weight:bold}
    

</style>