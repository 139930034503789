<template>
    <div class="content-warp">
        <!--导航-->
        <el-tabs type="card" v-model="cardActiveName">
            <el-tab-pane label="横屏形象" name="first">
                <div class="model-list">
                    <template v-for="(item,i) in humanList">
                        <template v-if="item.Resolution==='1920x1080'">
                        <div class="card-model">
                            <div class="cover">
                                <img :src="item.ThumbnailUrl"/>
                            </div>
                            <h2>{{item.AnchorName}}</h2>
                            <div class="time">
                                <span>剩余时长(分钟)</span>
                                <i>{{ parseInt(item.BalanceDuration/60) }}</i>
                            </div>
                            <!--<el-progress :text-inside="true" :stroke-width="16" :format="modelProgress" :percentage="item.BalanceDuration/36000*100" color="#67df42" define-back-color="#e5e5e5" class="progress"></el-progress>-->
                        </div>
                        </template>
                    </template>
                    <br clear="all"/>
                </div>
            </el-tab-pane>
            <el-tab-pane label="竖屏形象" name="second">
                <div class="model-list">
                    <template v-for="(item,i) in humanList">
                        <template v-if="item.Resolution==='1080x1920'">
                        <div class="card-model">
                            <div class="cover">
                                <img :src="item.ThumbnailUrl"/>
                            </div>
                            <h2>{{item.AnchorName}}</h2>
                            <div class="time">
                                <span>剩余时长(分钟)</span>
                                <i>{{ parseInt(item.BalanceDuration/60) }}</i>
                            </div>
                        </div>
                        </template>
                    </template>
                    <br clear="all"/>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import axios from 'axios'
  export default {
    data() {
      return {
        humanList:[],
        cardActiveName: 'first'
      };
    },
    created(){
        this.getHuman()
    },
    methods: {
        modelProgress(percentage){
            let tex = percentage+'/800分钟'
            return tex
        },
        downloadFile(url,id){
            console.log(url)
            axios.get(url, {
                responseType: 'blob' // 重点
            })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', id+'.png'); // 自定义文件名
                document.body.appendChild(link);
                link.click();
            });
        },
        async getHuman(){
        //接口---获取数字人资产
        const {data:res} = await this.$http.post('/get/assets',{
                sid:sessionStorage.getItem("token"),
            })
            
            switch(res.status){
                //获取成功
                case 200:
                    console.log("数字人数据")
                    console.log(res)
                    //数字人获取----------------
                    for (let key in res.anchors) {
                        let tempHuman = {
                            VirtualmanKey:res.anchors[key].VirtualmanKey,
                            HeaderImage:res.anchors[key].HeaderImage,
                            ThumbnailUrl:'http://49.232.143.40/human/'+res.anchors[key].VirtualmanKey+'.jpg',
                            AnchorName:res.anchors[key].AnchorName,
                            OriginZoom:res.anchors[key].OriginZoom,
                            BalanceDuration:res.anchors[key].balance_duration,
                            Resolution:res.anchors[key].Resolution
                        }
                        console.log(res.anchors[key].OriginZoom)
                        //写入数组
                        this.humanList.splice(key,0,tempHuman)
                        console.log("写入后的数据")
                        console.log(this.humanList)

                    }
                break

                //登录失效
                case 401:
                    this.$message.error('登录已过期'); 
                    this.$router.push("/login")
                break

                //获取失败
                default:
                    console.log(res)
                    setTimeout(() => {
                        this.getHuman()
                    }, 1000)
                    console.log('获取形象失败')
                break
            }
        },
    }
  };
</script>


<style lang="less" scoped>
    .model-list{padding:16px}
    .card-model{width:240px;height: 400px;background-image: linear-gradient(to bottom right, #f5f5f5, #f5f5f5);border-radius: 8px;float: left;margin: 0 32px 32px 0;}
    .card-model .cover{width:240px;height:320px;border-radius:8px 8px 0 0;box-shadow:0 6px 6px 0px rgba(0,0,0,0);position: relative;-webkit-transition-property:box-shadow;-webkit-transition-duration:1s;-webkit-transition-timing-function: ease;}
    .card-model .cover img{width: 100%;height:100%;border-radius:8px 8px 0 0}
    .card-model h2{font-size: 14px;padding: 18px 10px 0 14px;color: #333333;}
    .card-model .time {position: relative;padding-top:4px;}
    .card-model .time span{font-size: 12px;color: #999999;padding-left:14px;}
    .card-model .time i{position: absolute;right:20px;font-style: normal;font-size:24px;bottom:-2px;font-family:Arial, Helvetica, sans-serif;color:#409EFF;}

    /*.card-model:hover{background-image: linear-gradient(to bottom right, #6717cd, #2871fa);color: #ffffff;}
    .card-model:hover h2{color: #ffffff;}
    .card-model:hover .cover{box-shadow:0 6px 6px 0px rgba(0,0,0,0.2);-webkit-transition-property:box-shadow;-webkit-transition-duration:1s;-webkit-transition-timing-function: ease;}
    */
    .card-model .progress{margin:8px 10px 0 10px;}
    
</style>