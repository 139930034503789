import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import SlideVerify from 'vue-monoplasty-slide-verify'

//导入全局样式
import './assets/css/global.css'
import './assets/css/iconfont.css'

Vue.config.productionTip = false
Vue.use(SlideVerify)

//接口
import axios  from 'axios'
Vue.prototype.$http =axios
axios.defaults.baseURL='http://49.232.143.40:8088/'

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

// 正式环境清除所有console.log
if (process.env.NODE_ENV === 'production') {
  if (window) {
    window.console.log = function () {};
  }
}