<template>
    <div class="content-warp">
        <div class="page-title">最近项目</div>
            <div class="video-list">
                <!--
                <div class="overview">
                    <div class="data-card">
                        <div><br><br><b>0</b><br><font>生成中</font></div>
                        <div><br><br><b>5</b><br><a href="/#/creations/video">已完成</a></div>
                        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;概览<br><br><b>4</b><br><a href="/#/creations/video">草稿箱</a></div>
                    </div>
                    <div class="btn-video-add index" @click="createProject">
                        <span class="icon"><i class="el-icon-plus"></i>创建视频</span>
                    </div>
                </div>
                -->
                <div class="btn-video-add" @click="createProject">
                        <span class="icon"><i class="el-icon-plus"></i><br>创建视频</span>
                    </div>
                <div class="card-video" v-for="(item,index) in projectList" :key="index">
                    <!--未完成的-->
                    <template v-if="item.status===0">
                        <div class="cover">
                            <div class="edit">
                                <span class="state-unfinished">未完成</span>
                                <el-button type="danger" class="btn-edit" @click="editProject(item.projectid)">继续编辑</el-button>
                            </div>
                            <img :src="'http://49.232.143.40:8090/assets/2/'+item.thumbnail_url+'.jpg'"/>
                        </div>
                        <h2>
                            {{item.name}}
                            <!--
                            <el-tooltip class="item" effect="dark" content="重命名" placement="top">
                                <i class="el-icon-edit"></i>
                            </el-tooltip>
                            -->
                        </h2>
                        <el-row>
                            <el-col :span="14">
                                <font class="date">{{item.update_time | formatDate}}</font>
                            </el-col>
                            <el-col :span="9" style="text-align: right;" class="contrl">
                                <el-tooltip class="item" effect="dark" content="删除" placement="top">
                                    <i @click="deleteProject(item.projectid)" class="el-icon-delete tool-icon"></i>
                                </el-tooltip>
                            </el-col>
                        </el-row>
                    </template>
                    <!--生成中的-->
                    <template v-if="item.status===1">
                        <div class="cover">
                            <div class="edit">
                                <span class="state-output">生成暂停</span>
                                <div class="progress">
                                    <el-progress type="circle" :percentage="25" text-color="#ffffff" :width="120" :stroke-width="16" color="#67df24" define-back-color="rgba(255,255,255,0.7)"></el-progress>
                                </div>
                            </div>
                            <img :src="'http://49.232.143.40:8090/assets/2/'+item.thumbnail_url+'.jpg'"/>
                        </div>
                        <h2>
                            {{item.name}}
                            <!--
                            <el-tooltip class="item" effect="dark" content="重命名" placement="top">
                                <i class="el-icon-edit"></i>
                            </el-tooltip>
                            -->
                        </h2>
                        <el-row>
                            <el-col :span="14">
                                <font class="date">2023-10-18 16:23:20</font>
                            </el-col>
                            <el-col :span="9" style="text-align: right;" class="contrl">
                                <el-button size="mini" type="primary" @click="makeProject(item.projectid)">继续生成</el-button>
                            </el-col>
                        </el-row>
                    </template>
                    <!--已完成的-->
                    <template v-if="item.status===2">
                        <div class="cover">
                            <div class="video-play" @click="videoPlay(item.video_url)">
                                <span class="btn-play"><i class="el-icon-caret-right"></i></span>
                            </div>
                            <img :src="'http://49.232.143.40:8090/assets/2/'+item.thumbnail_url+'.jpg'"/>
                        </div>
                        <h2>
                            {{item.name}}
                            <!--
                            <el-tooltip class="item" effect="dark" content="重命名" placement="top">
                                <i class="el-icon-edit"></i>
                            </el-tooltip>
                            -->
                        </h2>
                        <el-row>
                            <el-col :span="14">
                                <font class="date">{{item.update_time | formatDate}}</font>
                            </el-col>
                            <el-col :span="9" style="text-align: right;" class="contrl">
                                <el-tooltip class="item" effect="dark" content="删除" placement="top">
                                    <i @click="deleteProject(item.projectid)" class="el-icon-delete tool-icon"></i>
                                </el-tooltip>
                                &nbsp;
                                <el-button size="mini" type="success" plain @click="downloadFile(item.video_url,item.name)">下载</el-button>
                            </el-col>
                        </el-row>
                    </template>

                </div>
               <!--
                <div class="card-video">
                    <div class="cover">
                        <div class="edit">
                            <span class="state-unfinished">未完成</span>
                            <el-button type="primary" class="btn-edit">继续编辑</el-button>
                        </div>
                        <img src="../assets/img/pic-8.jpg"/>
                    </div>
                    <h2>
                        新款折叠屏#华为PocketS
                        <el-tooltip class="item" effect="dark" content="重命名" placement="top">
                            <i class="el-icon-edit"></i>
                        </el-tooltip>
                    </h2>
                    <el-row>
                        <el-col :span="14">
                            <font class="date">2023-10-18 16:23:20</font>
                        </el-col>
                        <el-col :span="9" style="text-align: right;" class="contrl">
                            <el-tooltip class="item" effect="dark" content="复制并编辑" placement="top">
                                <i class="el-icon-copy-document"></i>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="删除" placement="top">
                                <i class="el-icon-delete"></i>
                            </el-tooltip>
                        </el-col>
                    </el-row>
                </div>
                <div class="card-video">
                    <div class="cover">
                        <div class="edit">
                            <span class="state-output">生成中</span>
                            <div class="progress">
                                <el-progress type="circle" :percentage="25" text-color="#ffffff" :width="120" :stroke-width="16" color="#67df24" define-back-color="rgba(255,255,255,0.7)"></el-progress>
                            </div>
                        </div>
                        <img src="../assets/img/pic-9.jpg"/>
                    </div>
                    <h2>
                        探索平衡，不止理想
                        <el-tooltip class="item" effect="dark" content="重命名" placement="top">
                            <i class="el-icon-edit"></i>
                        </el-tooltip>
                    </h2>
                    <el-row>
                        <el-col :span="14">
                            <font class="date">2023-10-18 16:23:20</font>
                        </el-col>
                    </el-row>
                </div>
                
                <div class="card-video">
                    <div class="cover">
                        <div class="video-play">
                            <span class="btn-play"><i class="el-icon-video-play"></i></span>
                        </div>
                        <img src="../assets/img/pic-10.jpg"/>
                    </div>
                    <h2>
                        IPhone 14 Pro Max Half Permeable
                        <el-tooltip class="item" effect="dark" content="重命名" placement="top">
                            <i class="el-icon-edit"></i>
                        </el-tooltip>
                    </h2>
                    <el-row>
                        <el-col :span="14">
                            <font class="date">2023-10-18 16:23:20</font>
                        </el-col>
                        <el-col :span="9" style="text-align: right;" class="contrl">
                            <el-tooltip class="item" effect="dark" content="下载" placement="top">
                                <i class="el-icon-download"></i>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="删除" placement="top">
                                <i class="el-icon-delete"></i>
                            </el-tooltip>
                        </el-col>
                    </el-row>
                </div>
                -->
            </div>
            <!--视频预览-->
            <el-dialog
                title="视频预览"
                :visible.sync="dialogVideoPlay"
                width="652px"
                :before-close="stopPlay"
                class="videoPlayBox">
                <video ref="videoPlay" :src="downloadUrl" autoplay controls width="640" height="360"></video>
            </el-dialog>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    axios,
    data(){
        return{
            projectList:[],
            dialogVideoPlay:false,
            downloadUrl:''
        }
    },
    computed:{
        // 数据排序
        items:function(){
            return this.item.sort(number);
        },
        sortStudent:function(){
            return sortByKey(this.students,'age')
        }
    },
    created(){
        //登录失效

        //查询
        this.getProject()
    },
    methods:{
        async getProject(){
            //接口---获取项目列表
            const {data:res} = await this.$http.post('/get/all/projects',{
                sid:sessionStorage.getItem("token")
            })
            console.log(res.status)
            //获取成功
            if(res.status=='200'){
                this.projectList=res.projects
            }
            //获取失败
            else{
                this.$message({
                    message: '获取数据失败',
                    type: 'error'
                })
                this.$router.push("/login")
            }
        },
        //创建项目
        async createProject(){
            //接口---创建项目
            const date = new Date();
            const year = date.getFullYear()
            const month = date.getMonth()+1
            const day = date.getDate()
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const seconds = date.getSeconds();
            const title = '未命名'+year+'-'+month+'-'+day+'-'+hours+':'+minutes+':'+seconds
            
            const {data:res} = await this.$http.post('/create/project',{
                sid:sessionStorage.getItem("token"),
                name:title
            })
            //创建成功
            if(res.status=='200'){
                this.$router.push({path:'/projects/creat',query:{projectid:res.projectid}})
            }
            //创建失败
            else{
                this.$message({
                    message: '创建项目失败',
                    type: 'error'
                });
            }


        },
        //编辑项目
        editProject(projectid){
            this.$router.push({path:'/projects/creat',query:{projectid:projectid}})
        },
        //删除项目
        deleteProject(projectid) {
            console.log(projectid)
        this.$confirm('是否删除当前项目', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
            
            //接口---删除项目
            const {data:res} = await this.$http.post('/delete/project',{
                sid:sessionStorage.getItem("token"),
                projectid:projectid
            })
            //删除成功
            if(res.status=='200'){
                this.$message({
                    message: '删除成功',
                    type: 'success'
                });
                this.getProject()
            }
            //删除失败
            else{
                this.$message({
                    message: '获取数据失败',
                    type: 'error'
                });
            }

        }).catch(() => {
            //取消操作
        });
      },

        //预览视频
        videoPlay(fileurl) {
            console.log("关闭")
            this.downloadUrl=fileurl
            this.dialogVideoPlay=true
            this.$refs.videoPlay.currentTime = 0
            video.play().catch((err)=>{
                console.log(err)
            })
            this.$refs.videoPlay.play()
        },
        stopPlay() {
            console.log("关闭")
            this.$refs.videoPlay.pause()
            this.dialogVideoPlay=false
        },
        //下载视频
        downloadFile(fileUrl,name2) {
            console.log('sdfh')
            axios.get(fileUrl, {
                responseType: 'blob' // 重点
            })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', name2+'.mp4'); // 自定义文件名
                document.body.appendChild(link);
                link.click();
            });
        },
        //继续生成视频
        makeProject(projectid){
            this.$router.push({path:'/projects/make',query:{projectid:projectid}})
        }
    },
    filters: {
        // 转换时间戳
        formatDate(value) {
            if (value == undefined) {
                return;
            }
            // let date = new Date(value * 1000);
            let date = new Date(value);
            //时间戳为10位需*1000，时间戳为13位的话不需乘1000
            let y = date.getFullYear();
            let MM = date.getMonth() + 1;
            MM = MM < 10 ? ('0' + MM) : MM; //月补0
            let d = date.getDate();
            d = d < 10 ? ('0' + d) : d; //天补0
            let h = date.getHours();
            h = h < 10 ? ('0' + h) : h; //小时补0
            let m = date.getMinutes();
            m = m < 10 ? ('0' + m) : m; //分钟补0
            let s = date.getSeconds();
            s = s < 10 ? ('0' + s) : s; //秒补0
            // return y + '-' + MM + '-' + d; //年月日
            return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s; //年月日时分秒
        }

    }
}
</script>
<style lang="less" scoped>
    .overview{width:320px;height: 260px;border-radius: 8px;float: left;margin: 0 32px 32px 0;}
    .data-card{height: 170px;background: url(../assets/img/data-bg.jpg);background-size:100% 100%;border-radius: 8px;margin-bottom: 16px;display: flex;color: #ffffff;}
    .data-card div{flex:1;text-align: center;padding-top: 4px;}
    .data-card div b{font-size: 40px;line-height:64px;}
    .data-card div a{;color: #ffffff;}
    .data-card div a:hover{opacity:0.7}
    .data-card div font{opacity:0.7}
</style>