<template>
    <div id="app">
        <div id="test"></div>
        <el-button size="mini" type="primary" plain @click="getImg">导出图片</el-button>
    <div id="content" ref="content" style="background-color: aqua;height: 100%;background-image: url('http://49.232.143.40:8090/images/5153f60dc673a46d230e7d4a0e7d37e2_1.jpg');">
      <!--这里是需要截图的区域-->
        <img src="http://49.232.143.40:8090/images/8c9be46599781c12cd020718d6ab9164_1.png"/>
    </div>
    </div>
</template>
<script>
import html2canvas from 'html2canvas'
export default {
    name: 'Drag',
    components: {
    html2canvas
  },
  data() {
    return {
        
    }},
    methods:{
        getImg(){
            html2canvas(
                document.getElementById('content'),
                {
                backgroundColor:null,//画出来的图片有白色的边框,不要可设置背景为透明色（null）
                useCORS: true,//支持图片跨域
                scale:1,//设置放大的倍数
                }
            ).then(canvas => {
                //截图用img元素承装，显示在页面的上
                let img = new Image();
                img.crossOrigin = '*'; // 图片处理跨域
                img.src = canvas.toDataURL('image/jpeg');// toDataURL :图片格式转成 base64

                const fileInput = this.DataURIToBlob(img.src)
                console.log(fileInput)
                document.getElementById('test').appendChild(img);

                //上传
                const formData = new FormData();
                formData.append('file', fileInput);
                var xhr =new XMLHttpRequest();

                xhr.open('POST','http://49.232.143.40:8089/?action=upload&filename=封面&cid=封面&sid='+sessionStorage.getItem("token")+'&ext=jpg&type=2&thumbnail=false&fileid='+this.thumbnailUrl)
                xhr.onload  = () => {
                    if(xhr.status ===200){

                        this.$message.success('文件上传成功');
                        this.uploadImgListFirstLoad=false
                        console.log('onreadystatechange success'+xhr.status +' '+xhr.responseText);
                    }else {

                        this.$message.error('文件上传失败');
                        console.log('onreadystatechange fialed'+xhr.status +'  '+xhr.responseText);
                    }
                }
                xhr.send(formData);
                
                    //如果你需要下载截图，可以使用a标签进行下载
                    /*
                    let a = document.createElement('a');
                    a.href = canvas.toDataURL('image/jpeg');
                    a.download = 'test';
                    a.click();
                    */
                })
            },
            //base64转
            DataURIToBlob(dataURI) {
                const splitDataURI = dataURI.split(',')
                const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
                const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

                const ia = new Uint8Array(byteString.length)
                for (let i = 0; i < byteString.length; i++)
                    ia[i] = byteString.charCodeAt(i)

                return new Blob([ia], { type: mimeString })
            }

        
    }
    }
    
</script>
<style>
    #app{height: 100%;}
    .creat-right{width: 80px;background: #242438;height: 100%;}
    .creat-right .video-right-bar{border-top:1px solid rgba(255,255,255,0.1);}
    .creat-right .video-right-bar ul.right-nav{display: block;}
    .creat-right .video-right-bar ul.right-nav li{display: block; color:#A4A4B0;text-align: center;margin-top:32px;}
    .creat-right .video-right-bar ul.right-nav li:hover{opacity:0.6;}
    .creat-right .video-right-bar ul.right-nav li i{font-size:28px;}
    .creat-right .video-right-bar ul.right-nav li.cur{color: #1989FA;}
    h3{color: #Ffffff;padding:16px;}
    .el-drawer.rtl{background: #242438;}
</style>