<template>
    <div class="content-warp">
        <!--导航-->
        <el-tabs type="card" v-model="cardActiveName">
            <el-tab-pane label="已完成" name="first">
                <div class="video-list">
                    <template v-for="(item) in projectList">
                        <template v-if="item.status===2">
                            <div class="card-video">
                                <div class="cover">
                                    <div class="video-play" @click="videoPlay(item.video_url)">
                                        <span class="btn-play"><i class="el-icon-caret-right"></i></span>
                                    </div>
                                    <img :src="'http://49.232.143.40:8090/assets/2/'+item.thumbnail_url+'.jpg'"/>
                                </div>
                                <h2>
                                    {{item.name}}
                                </h2>
                                <el-row>
                                    <el-col :span="14">
                                        <font class="date">{{item.update_time | formatDate}}</font>
                                    </el-col>
                                    <el-col :span="9" style="text-align: right;" class="contrl">
                                        <el-tooltip class="item" effect="dark" content="删除" placement="top">
                                            <i class="el-icon-delete tool-icon"></i>
                                        </el-tooltip>
                                        &nbsp;
                                        <el-button size="mini" type="success" plain @click="downloadFile(item.video_url,item.name)">下载</el-button>
                                    </el-col>
                                </el-row>
                            </div>
                        </template>
                    </template>
                </div>
                
            </el-tab-pane>
            <el-tab-pane label="草稿箱" name="second">
                <div class="video-list">
                    <!--
                    <div class="btn-video-add" @click="createProject">
                        <span class="icon"><i class="el-icon-plus"></i><br>创建视频</span>
                    </div>
                    -->
                    <template v-for="(item) in projectList">
                        <template v-if="item.status===0">
                            <div class="card-video">
                                <div class="cover">
                                    <div class="edit">
                                        <span class="state-unfinished">未完成</span>
                                        <el-button type="danger" class="btn-edit" @click="editProject(item.projectid)">继续编辑</el-button>
                                    </div>
                                    <img :src="'http://49.232.143.40:8090/assets/2/'+item.thumbnail_url+'.jpg'"/>
                                </div>
                                <h2>
                                    {{item.name}}
                                </h2>
                                <el-row>
                                    <el-col :span="14">
                                        <font class="date">{{item.update_time | formatDate}}</font>
                                    </el-col>
                                    <el-col :span="9" style="text-align: right;" class="contrl">
                                        <el-tooltip class="item" effect="dark" content="删除" placement="top">
                                            <i @click="deleteProject(item.projectid)" class="el-icon-delete tool-icon"></i>
                                        </el-tooltip>
                                    </el-col>
                                </el-row>
                            </div>
                        </template>
                    </template>
                    <br clear="all"/>
                </div>
            </el-tab-pane>
        </el-tabs>
        <!--视频预览-->
        <el-dialog
                title="视频预览"
                :visible.sync="dialogVideoPlay"
                width="652px"
                :before-close="stopPlay"
                class="videoPlayBox">
                <video ref="videoPlay" :src="downloadUrl" autoplay controls width="640" height="360"></video>
            </el-dialog>
    </div>
</template>
<script>
import axios from 'axios'
  export default {
    axios,
    data() {
      return {
        cardActiveName: 'first',
        projectList:[],
        dialogVideoPlay:false,
        downloadUrl:''
      };
    },
    created(){
        //查询
        this.getProject()
    },
    methods: {
        async getProject(){
            //接口---获取项目列表
            const {data:res} = await this.$http.post('/get/all/projects',{
                sid:sessionStorage.getItem("token")
            })
            console.log(res.status)
            //获取成功
            if(res.status=='200'){
                this.projectList=res.projects
                console.log(this.projectList)
            }
            //获取失败
            else{
                this.$message({
                    message: '获取数据失败',
                    type: 'error'
                })
                this.$router.push("/login")
            }
        },
        //创建项目
        async createProject(){
            //接口---创建项目
            const date = new Date();
            const year = date.getFullYear()
            const month = date.getMonth()+1
            const day = date.getDate()
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const seconds = date.getSeconds();
            const title = '未命名'+year+'-'+month+'-'+day+'-'+hours+':'+minutes+':'+seconds
            
            const {data:res} = await this.$http.post('/create/project',{
                sid:sessionStorage.getItem("token"),
                name:title
            })
            //创建成功
            if(res.status=='200'){
                this.$router.push({path:'/projects/creat',query:{projectid:res.projectid}})
            }
            //创建失败
            else{
                this.$message({
                    message: '创建项目失败',
                    type: 'error'
                });
            }


        },
        //编辑项目
        editProject(projectid){
            this.$router.push({path:'/projects/creat',query:{projectid:projectid}})
        },
        //删除项目
        deleteProject(projectid) {
            console.log(projectid)
        this.$confirm('是否删除当前项目', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
            
            //接口---删除项目
            const {data:res} = await this.$http.post('/delete/project',{
                sid:sessionStorage.getItem("token"),
                projectid:projectid
            })
            //删除成功
            if(res.status=='200'){
                this.$message({
                    message: '删除成功',
                    type: 'success'
                });
                this.getProject()
            }
            //删除失败
            else{
                this.$message({
                    message: '获取数据失败',
                    type: 'error'
                });
            }

        }).catch(() => {
            //取消操作
        });
      },

        //预览视频
        videoPlay(fileurl) {
            console.log("关闭")
            this.downloadUrl=fileurl
            this.dialogVideoPlay=true
            this.$refs.videoPlay.currentTime = 0
            video.play().catch((err)=>{
                console.log(err)
            })
            this.$refs.videoPlay.play()
        },
        stopPlay() {
            console.log("关闭")
            this.$refs.videoPlay.pause()
            this.dialogVideoPlay=false
        },
        //下载视频
        downloadFile(fileUrl,name2) {
            console.log('sdfh')
            axios.get(fileUrl, {
                responseType: 'blob' // 重点
            })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', name2+'.mp4'); // 自定义文件名
                document.body.appendChild(link);
                link.click();
            });
        }
    },
    filters: {
        // 转换时间戳
        formatDate(value) {
            if (value == undefined) {
                return;
            }
            // let date = new Date(value * 1000);
            let date = new Date(value);
            //时间戳为10位需*1000，时间戳为13位的话不需乘1000
            let y = date.getFullYear();
            let MM = date.getMonth() + 1;
            MM = MM < 10 ? ('0' + MM) : MM; //月补0
            let d = date.getDate();
            d = d < 10 ? ('0' + d) : d; //天补0
            let h = date.getHours();
            h = h < 10 ? ('0' + h) : h; //小时补0
            let m = date.getMinutes();
            m = m < 10 ? ('0' + m) : m; //分钟补0
            let s = date.getSeconds();
            s = s < 10 ? ('0' + s) : s; //秒补0
            // return y + '-' + MM + '-' + d; //年月日
            return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s; //年月日时分秒
        }

    }
  };
</script>
<style lang="less" scoped>

</style>