import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login.vue'
import Home from '../components/Home.vue'
import CreationsVideo from '../components/CreationsVideo.vue'
import Balance from '../components/Balance.vue'
import ProjectsCreat from '../components/ProjectsCreat.vue'
import Projects from '../components/Projects.vue'
import Make from '../components/Make.vue'
import Test from '../components/Test.vue'

Vue.use(VueRouter)

const routes = [
  {path:'/',redirect:'/login'},
  {path:'/login',component:Login,meta:{title: 'DigitalTwin数字分身平台'}},
  {path:'/home',
    component:Home,
    meta:{
      title: 'DigitalTwin数字分身平台'
    },
    redirect:'/projects',
    children:[
      {path:'/projects',component:Projects,meta:{title: '最近项目-DigitalTwin数字分身平台'}},
      {path:'/projects/creat',component:ProjectsCreat,meta:{title: '编辑项目-DigitalTwin数字分身平台'}},
      {path:'/projects/make',component:Make,meta:{title: '视频合成-DigitalTwin数字分身平台'}},
      {path:'/creations/video',component:CreationsVideo,meta:{title: '所有项目-DigitalTwin数字分身平台'}},
      {path:'/balance',component:Balance,meta:{title: '我的资产-DigitalTwin数字分身平台'}},
      {path:'/test',component:Test,meta:{title: '我的资产-DigitalTwin数字分身平台'}},
    ]
}
]

const router = new VueRouter({
  routes
})

//挂载路由卫士
router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  if (to.path === '/login') {
    next();
  } else {
    let token = window.sessionStorage.getItem('token')
    //判断登录
    if (token === null || token === '') {
      next('/login');
    } else {
      next();
    }
  }
});

export default router
