<template>
    <div class="creat-page">
        <!--创建视频导航-->
        <div class="header-creat">
            <el-row>
                <el-col :span="12">
                    <h2>
                        {{title}}
                    </h2>
                    
                </el-col>
            </el-row>
        </div>
        <div class="creat-warp">

            <!--视频编辑区--> 
            <div class="creat-center">
                <div class="creat-center-warp">
                    <div class="video-warp">
                        <div class="video-box">
                            <div class="cover">
                                <img class="bg" :src="pageViewList.pages[0].backgroundFileUrl"/>
                                <img class="logo" v-show="pageViewList.pages[0].logoParams[0].fileUrl == ''? false:true" :src="pageViewList.pages[0].logoParams[0].fileUrl" :style="{top:pageViewList.pages[0].logoParams[0].positionY+'px',left:pageViewList.pages[0].logoParams[0].positionX+'px',width:pageViewList.pages[0].logoParams[0].width*pageViewList.pages[0].logoParams[0].scale+'px',height:pageViewList.pages[0].logoParams[0].height*pageViewList.pages[0].logoParams[0].scale+'px'}"/>
                                <img class="human" :src="pageViewList.pages[0].humanParams[0].fileUrl" :style="{top:pageViewList.pages[0].humanParams[0].positionY+'px',left:pageViewList.pages[0].humanParams[0].positionX+'px',width:pageViewList.pages[0].humanParams[0].width*pageViewList.pages[0].humanParams[0].scale+'px',height:pageViewList.pages[0].humanParams[0].height*pageViewList.pages[0].humanParams[0].scale+'px'}"/>
                            </div>
                        </div>
                        <div class="video-control">
                            <h1>{{statusTxt}}</h1>
                            <div class="progress">
                                <el-progress v-show="this.status == 0 ? true:false"  type="circle" :percentage="progressNum" text-color="#333333" :width="200" :stroke-width="26" color="#67df24" define-back-color="rgba(220,220,220,1)"></el-progress>
                                <div v-show="this.status == 0 ? true:false" style="text-align:center;margin-top:10px">{{ makeTips }}</div>
                                <div v-show="this.status == 1 ? true:false">
                                    <br>
                                    <el-button type="success" class="btn-video-control" @click="videoPlay"><i class="el-icon-video-play"></i> 预览</el-button>
                                    <br>
                                    <el-button type="primary" class="btn-video-control" @click="downloadFile(downloadUrl)">下载视频</el-button>
                                    <br><br>
                                    <el-button type="plain" class="btn-video-control" plain>再创建一个</el-button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--视频预览-->
        <el-dialog
            title="视频预览"
            :visible.sync="dialogVideoPlay"
            width="652px"
            :before-close="stopPlay"
            class="videoPlayBox">
            <video ref="videoPlay" :src="downloadUrl" autoplay controls width="640" height="360"></video>
        </el-dialog>
    </div>
    
    </template>
    <script>
import axios from 'axios'
export default {
    axios,
    data(){
        return{
           title:'',
           projectid:'',
           progress:'',
           //获取失败重试次数
           saveTemp:0,
           pageViewList:'',
           itemList:[],
           progressNum:0,
           status:0,
           statusTxt:'视频生成中...',
           downloadUrl:'',
           makeTips:'',
           videoHeadFileUrl:'',
           videoTailFileUrl:'',
           dialogVideoPlay:false,
           progressInterval:'',
           cover:{
                bg:'',
                logo:'',
                human:''
            }
        }
    },
    created(){
        //使用创建布局
        this.$emit('hideTopbar');
        //查询
        this.projectid=this.$route.query.projectid
        if(this.projectid==undefined){
            //无ID终止
            this.$message({
                message: '参数错误',
                type: 'error'
            });
            return false;
        }else{
            //制作视频
            this.init()
        }
    },
    methods: {
        //进度状态
        progressv(){          
            this.progressInterval=setInterval(() => {
                this.queryVideo()
            }, 1000);
        },
        //初始化
        async init(){
            if(await this.getProject() ==0){
                //未制作的项目
                if(await this.getProjectItem()=='success'){
                    if(this.itemList.length==0){
                        //未创建子项目
                        if(await this.createProjectItem()=='error'){
                            console.log('子项目创建失败')
                            return false
                        }else{
                            this.progressv() 
                        }
                        
                    }else{
                        this.progressv() 
                    }

                     
                }
            }else{
                if(await this.getProjectItem()=='success'){
                    console.log("去查询")
                    this.tipsClose()
                    this.progressv()
                }
                
            }
            
            //console.log(this.itemList)
        },
        //接口---获取项目信息
        async getProject(){
        const {data:res} = await this.$http.post('/get/project',{
                sid:sessionStorage.getItem("token"),
                projectid:this.projectid
            })
            //获取成功
            if(res.status=='200'){
                this.title=res.projects[0].name
                this.pageViewList=JSON.parse(res.projects[0].details)
                this.videoHeadFileUrl=this.pageViewList.videoHeadFileUrl
                this.videoTailFileUrl=this.pageViewList.videoTailFileUrl
                console.log("项目信息")
                console.log("片头"+this.videoHeadFileUrl)
                console.log("片尾"+this.videoTailFileUrl)
                return res.projects[0].status
            }
            //获取失败
            else{
            //重试10次
            this.saveTemp=this.saveTemp+1
                if(this.saveTemp<10){
                    setTimeout(() => {
                        this.getProject()
                    }, 500);
                }else{
                    
                    this.$message({
                        message: '获取失败',
                        type: 'error'
                    });
                    this.saveTemp=0
                    console.log("获取项目失败")
                    return 'error'
                }
            }
        },
        //接口-获取所有子项
        async getProjectItem(){
            const {data:res} = await this.$http.post('/get/all/project/items',{
                sid:sessionStorage.getItem("token"),
                projectid:this.projectid
            })
            const arryItem=[]
            //获取成功
            if(res.status=='200'){
                this.itemList=res.items
                //console.log(res.items)
                return 'success'
            }else{
                console.log("获取子项目失败")
                return 'error'
            }
        },

        //接口-创建子项
        async createProjectItem(){
            const arryItem=[]
            const l=this.pageViewList.pages.length
            //const projectItem=
            //写入数组
            for (let i=0; i<l; i++) {
                const item={'name':'第'+(i+1)+'页'}
                arryItem.splice(i,0,item)
            }
            console.log(sessionStorage.getItem("token"))
            console.log(this.projectid)
            console.log(arryItem)

            const {data:res} = await this.$http.post('/create/project/item',{
                sid:sessionStorage.getItem("token"),
                projectid:this.projectid,
                items:arryItem
            })
            if(res.status=='200'){
                this.itemList=res.items
                return 'success'
                
            }else{
                console.log("创建子项目失败")
                return 'error'
            }
        },
        //查询制作进度
        async queryVideo(){
            console.log("查询进度流程2")
            console.log(this.pageViewList)
            const l=this.itemList.length
                    console.log(this.itemList)
                    console.log(this.pageViewList)
                    console.log(this.pageViewList.pages[0].humanParams[0].width)
                    console.log('宽转换+偏移')
                    console.log(((this.pageViewList.pages[0].humanParams[0].positionX/1920)-0.5+(this.pageViewList.pages[0].humanParams[0].width*this.pageViewList.pages[0].humanParams[0].scale)/1920/2).toFixed(2))
                    console.log('高转换+偏移')
                    console.log(((this.pageViewList.pages[0].humanParams[0].positionY/1080)-0.5+(this.pageViewList.pages[0].humanParams[0].height*this.pageViewList.pages[0].humanParams[0].scale)/1080/2).toFixed(2))
                    
                    const scale=(this.pageViewList.pages[0].humanParams[0].scale*this.pageViewList.pages[0].humanParams[0].width)/(this.pageViewList.pages[0].humanParams[0].originZoom*this.pageViewList.pages[0].humanParams[0].width)
                    const anchorWidth=this.pageViewList.pages[0].humanParams[0].width * scale
                    const anchorHeight=this.pageViewList.pages[0].humanParams[0].height * scale
                    console.log('缩放')
                    console.log(this.pageViewList.pages[0].humanParams[0].scale)
                    console.log('x转换')
                    console.log(scale)
                    console.log((this.pageViewList.pages[0].humanParams[0].positionX+anchorWidth/2-960)/1920)
                    console.log((anchorHeight+this.pageViewList.pages[0].humanParams[0].positionY-1080)/2/anchorHeight)
            for (let i=0; i<l; i++) {
                //制作中的走查询
                if(await this.getProjectItem()=='success'){
                    //console.log(this.itemList)
                    console.log("查询进度流程")
                    //查询当前子项是否提交了创建
                    if(this.itemList[i].taskid ===''){
                        this.makeVideo(i)
                        console.log("创建第"+i+"页")
                        break;
                    }
                    //TaskID不为空，进度为100、不是最后一页、创建下一页
                    else if(this.itemList[i].progress<100){
                        this.progressNum=Math.floor((100/l)*i+(100/l)*this.itemList[i].progress)
                        //console.log("创建第"+i+"页")
                        //this.makeVideo(i+1)
                        console.log("第"+i+"页生成中...")
                        const number=i+1
                        this.makeTips="第"+number+"页生成中，共"+l+"页"
                        break;
                    }else if(i<l){
                        if(await this.queryProgress(i)!='error'){
                            //this.makeTips="第"+i+"页生成成功"
                            console.log("第"+i+"页生成成功")
                            if(i+1===l){
                                this.progressNum=100
                                this.status=1
                                this.statusTxt='创建成功'
                                console.log("全部创建完成")
                                this.makeTips="全部创建完成"
                                clearInterval(this.progressInterval)
                            }                  
                        }
                        
                    }else{
                        console.log("第1页创建中")
                    }
                    console.log(i)
                    console.log(l)
                }
            }
        },
        //接口-创建视频
        async makeVideo(i){
            //console.log("创建获取")
            let videoTailFileUrl=''
            if(i+1===this.itemList.length){
                videoTailFileUrl=this.videoTailFileUrl
            }
            console.log(this.pageViewList.pages[i].logoParams[0].fileUrl)
            const scale=this.pageViewList.pages[i].humanParams[0].scale/this.pageViewList.pages[i].humanParams[0].originZoom
            const anchorWidth=this.pageViewList.pages[i].humanParams[0].width * scale * this.pageViewList.pages[i].humanParams[0].originZoom
            const anchorHeight=this.pageViewList.pages[i].humanParams[0].height * scale * this.pageViewList.pages[i].humanParams[0].originZoom
            let logoUrl=this.pageViewList.pages[i].logoParams[0].fileUrl
            //转义输入内容中<、>、& 、"、'
            const InputSsml=this.pageViewList.pages[i].text.replace(/&/g,"&amp;").replace(/</g,"&lt;").replace(/>/g,"&gt;").replace(/"/g,"&quot;").replace(/'/g,"&apos;") 
            if(logoUrl===''){
                logoUrl='https://livebroadcast.gensee.com/demo/human/null.png'
            }
            const {data:res} = await this.$http.post('/make/video',{
                sid:sessionStorage.getItem("token"),
                item:this.itemList[i].item,
                make_video_info:{
                    VirtualmanKey:this.pageViewList.pages[i].virtualmanKey,
                    InputSsml:InputSsml,
                    VideoParam:{
                        BackgroundFileUrl:this.pageViewList.pages[i].backgroundFileUrl,
                        VideoHeadFileUrl:this.videoHeadFileUrl,
                        VideoTailFileUrl:videoTailFileUrl,
                        LogoParams:[{
                            LogoFileUrl:logoUrl,
                            PositionX:this.pageViewList.pages[i].logoParams[0].positionX,
                            PositionY:this.pageViewList.pages[i].logoParams[0].positionY,
                            Scale:this.pageViewList.pages[i].logoParams[0].scale
                        }],
                        AnchorParam:{
                            HorizontalPosition:(this.pageViewList.pages[i].humanParams[0].positionX+anchorWidth/2-960)/1920,
                            VerticalPosition:(anchorHeight+this.pageViewList.pages[i].humanParams[0].positionY-1080)/2/anchorHeight,
                            Scale:scale
                        }
                    },
                    SpeechParam:{
                        TimbreKey:this.pageViewList.pages[i].timbreParams[0].timbreKey,
                        Speed:this.pageViewList.pages[i].timbreParams[0].timbreSpeed
                    }
                }

            })
            if(res.status=='200'){
                console.log(console.log("第"+i+"生成成功"))
            }else{
                console.log(res)
                console.log(console.log("第"+i+"生成失败"))
            }
            
        },
        //接口-获取进度信息
        async queryProgress(i){
            //console.log("创建获取")
            const {data:res} = await this.$http.post('/query/progress',{
                sid:sessionStorage.getItem("token"),
                item:this.itemList[i].item,
                taskid:this.itemList[i].taskid
            })
            if(res.status=='200'){
                console.log(res)
                this.videoHeadFileUrl=res.progress.MediaUrl
                this.downloadUrl=res.progress.MediaUrl
                console.log(console.log("获取进度成功"))
                return res.progress.MediaUrl
            }else{
                console.log(console.log("获取进度失败"))
                return 'error'
            }
        },
        //预览视频
        videoPlay() {
            console.log("关闭")
            this.dialogVideoPlay=true
            this.$refs.videoPlay.currentTime = 0
            this.$refs.videoPlay.play()
        },
        stopPlay() {
            console.log("关闭")
            this.$refs.videoPlay.pause()
            this.dialogVideoPlay=false
        },
        //下载视频
        downloadFile(fileUrl) {
            axios.get(fileUrl, {
                responseType: 'blob' // 重点
            })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', this.title+'.mp4'); // 自定义文件名
                document.body.appendChild(link);
                link.click();
            });
        },
        //不要关闭提示
        tipsClose() {
        this.$notify({
          title: '渲染队列创建成功',
          message: '请勿关闭当前页面，关闭后将暂停合成',
          type: 'success',
          duration: 15000,
          offset: 100,
        });
      },
    }
}
    </script>
    <style lang="less" scoped>
        .creat-page{height: 100%;}
        .header-creat{height: 60px;background-color: #242438;padding: 0 16px;}
        .header-creat h2{color:#ffffff;font-size:16px;line-height: 60px;text-indent:8px;}
        .header-creat .creat-toolbar{height: 60px;display: flex;justify-content:flex-end;}
        .header-creat .creat-toolbar .middle{align-self:center}
        .creat-warp{box-sizing: border-box;display: flex;height:calc(100% - 60px);background-image: url(../assets/img/make-bg.jpg);background-size:cover;background-position:center center}
        .creat-left{width: 280px;background:#F4F4F6;}
        .creat-left-warp{height: 100%;box-sizing: border-box;height: 100%;padding:20px;overflow: auto;}
        
        .video-warp{width:1200px; height:480px;position:absolute;top: 50%;left: 50%;;margin:-200px 0 0 -600px;}
        .video-warp .video-box{float: left;border-radius: 16px;overflow: hidden;}
        .video-warp .video-box .cover{width:1920px;height:1080px;zoom:0.417;border-radius: 4px;overflow: hidden;position: relative;background-color:#49a253}
        .video-warp .video-box .cover img{position:absolute}
        .video-warp .video-box .cover img.bg{width: 100%;height: 100%;}
        .video-warp .video-control{float: right;width:380px;height: 450px;background-color:rgba(255,255,255,0.9);border-radius:16px;}
        .video-warp .video-control h1{font-size:22px;color:#333333; text-align: center;width:100%;margin-top:80px;}
        .video-warp .video-control .progress{text-align: center;padding-top:30px;width:100%;margin: 0 auto;}
        .video-warp .video-control .btn-video-control{width: 200px;height:48px;font-size:16px;margin:8px 0;}
    </style>