<template>
    <el-container>
        <el-aside :width="AsideWidth" :style="AsideStyle">
            <div :class="showLogo">
                <img src="../assets/img/logo.png"/>
            </div>
            <el-menu :default-active="mainMenuActive" @select="handleSelect" :collapse="isCollapse" class="left-menu" text-color="rgba(255,255,255,0.6)" active-text-color="#ffffff" background-color="rgba(0,0,0,0)" router>
                <el-menu-item index="/projects">
                    <i class="iconfont icon-custom-project"></i>
                    <span slot="title">&nbsp;最近项目</span>
                </el-menu-item>
                <!--
                <el-menu-item index="/projects/creat">
                    <i class="iconfont icon-custom-video"></i>
                    <span slot="title">&nbsp;创建视频</span>
                </el-menu-item>
                -->
                <el-menu-item index="/creations/video">
                    <i class="iconfont icon-custom-resource"></i>
                    <span slot="title">&nbsp;资源列表</span>
                </el-menu-item>
                <el-menu-item index="/balance">
                    <i class="iconfont icon-custom-money"></i>
                    <span slot="title">&nbsp;余额查询</span>
                </el-menu-item>
            </el-menu>
            <div :class="showDisk">
                存储空间：0.2G/5G
                <div class="disk-line"><span style="width: 40%;"></span></div>
            </div>
        </el-aside>
        <el-main :style="styleElMain">
            <!--页头-->
            <div :class="showHead">
                <!--标准导航-->
                <div class="header">
                    <el-row>
                        <el-col :span="16">
                            <el-breadcrumb separator="/">
                                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                                <el-breadcrumb-item>{{ menuname }}</el-breadcrumb-item>
                            </el-breadcrumb>
                        </el-col>
                        <el-col :span="8" style="text-align: right;">
                            <div class="avatar-breadcrumb">
                                <el-avatar size="small" class="avatar-bg"> 管 </el-avatar>
                                <el-dropdown trigger="click">
                                    <span class="el-dropdown-link">
                                        管理员<i class="el-icon-arrow-down el-icon--right"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item icon="el-icon-setting">设置</el-dropdown-item>
                                        <el-dropdown-item icon="el-icon-switch-button" @click.native="loginOut()">退出</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                 
            </div>
            <!--内容区-->
            <router-view @hideTopbar="hideTopbar"></router-view>
        </el-main>
    </el-container>
</template>
<script>
  export default {
    data() {
      return {
        mainMenuActive:'/projects',
        menuname: '项目',
        AsideWidth:'200px',
        AsideStyle:'',
        isCollapse:false,
        showLogo:'logo',
        showDisk:'disk',
        showHead:'header-warp',
        styleElMain:'',
        videoType:'0'
      };
    },
    created(){
        //刷新获取菜单并调整布局
        this.handleSelect(window.sessionStorage.getItem('mainMenuActive'));
    },
    methods: {
        //主导航点击事件
        handleSelect(obj) {
            console.log(obj)
            //初始化
            window.sessionStorage.setItem('mainMenuActive',obj);
            this.mainMenuActive=obj;
            this.AsideWidth="200px";
            this.AsideStyle="";
            this.isCollapse=false;
            this.showDisk='disk';
            this.showHead='header-warp';
            this.showLogo='logo';
            this.styleElMain=''
            //导航样式切换
            switch(obj){
                case '/projects':
                    this.menuname="最近项目";
                    break;
                case '/projects/creat':
                case '/projects/make':
                    this.menuname="创建视频";
                    this.AsideWidth="86px";
                    this.AsideStyle="border-radius:0 0 0 0;";
                    this.isCollapse=true;
                    this.showDisk='hide';
                    this.showLogo='logo-hide';
                    this.showHead='header-warp-creat';
                    this.styleElMain='padding:0'
                    break;
                case '/creations/video':
                    this.menuname="资源列表";
                    break;
                case '/balance':
                    this.menuname="余额查询";
                    break;
                default:
                    this.menuname="项目";
                    break;
                
            }
      },
      hideTopbar(){
        this.menuname="创建视频";
        this.AsideWidth="86px";
        this.AsideStyle="border-radius:0 0 0 0;";
        this.isCollapse=true;
        this.showDisk='hide';
        this.showLogo='logo-hide';
        this.showHead='header-warp-creat';
        this.styleElMain='padding:0'
      },
      //退出登录
      loginOut(){
        console.log("退出登录")
        window.sessionStorage.removeItem('token')
        this.$router.push("/login")
      }
    }
  };
</script>
<style lang="less" scoped>
</style>