import Vue from 'vue'
import {Row,Col,Button,ButtonGroup,Form,FormItem,Input,Checkbox,Select,Option,Message,Link,Container,Main,Aside,Breadcrumb,BreadcrumbItem,Menu,MenuItem,MenuItemGroup,Submenu,Tabs,TabPane,Avatar,Dropdown,DropdownItem,DropdownMenu,Card,Tooltip,Empty,Progress,Drawer,Dialog,MessageBox,Tag,Upload,Loading,Notification} from 'element-ui'

Vue.use(Row)
Vue.use(Col)
Vue.use(Button)
Vue.use(ButtonGroup)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Checkbox)
Vue.use(Select)
Vue.use(Option)
Vue.use(Link)
Vue.use(Container)
Vue.use(Main)
Vue.use(Aside)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Submenu)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Avatar)
Vue.use(Dropdown)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(Card)
Vue.use(Tooltip)
Vue.use(Empty)
Vue.use(Progress)
Vue.use(Drawer)
Vue.use(Dialog)
Vue.use(Tag)
Vue.use(Upload)
Vue.use(Loading)

Vue.prototype.$message = Message
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$notify = Notification